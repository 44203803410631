<template>
  <div class="content-block">
    <div class="content-header">
      Реестр проверок (аудитов)
      <div class="content-header__management">
        <div class="dropdown">
          <dropdownListWithHeader
            :search="false"
            header="Выберите направление"
            placeholder="Выберите направление"
            :list="organizationMedTypesPermission"
            @selectHandler="medTypeSelect"
          />
        </div>
        <nextButton
          :class="{ inactive: !selectedMedOrgId }"
          text="Создать проверку"
          :color="'green'"
          @clickHandler="createCheckList"
        />
      </div>
    </div>

    <div class="audit-list" :class="{ 'disabled-by-tarif': !hasAccess }">
      <NAlert v-if="!hasAccess" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
        Для получения полного доступа необходимо сменить тариф
      </NAlert>

      <div class="audit-list__header">Проверки (аудиты) качества и безопасности</div>
      <div class="filters">
        <div class="period-filter">
          <div class="filter-header">Период:</div>
          <div class="filter-inputs">
            <input class="date-input" type="date" @change="changeStartDate" />
            <div class="filter-delimiter">-</div>
            <input class="date-input" type="date" @change="changeStopDate" />
          </div>
        </div>
        <dropdownListWithHeader
          :key="filters.stopDate + filters.startDate"
          style="min-width: 250px"
          placeholder="Выберите статус"
          header="Статус"
          :search="false"
          :list="allStatuses"
          @selectHandler="changeStatus"
        />
        <!--        <inputWithHeader style="margin-left: 30px; margin-right: 30px;"
                          header="Вид проверки"
                         placeholder="Введите вид проверки"
                         @inputHandler="criteryInput"
        >
        </inputWithHeader>-->
      </div>
      <div class="list">
        <div class="list-header">
          <div class="number-row sort-item" @click="changeSort('number')">
            №
            <span class="sort-icon" :class="[{ active: sort.type === 'number' }, sort.direction]" />
          </div>
          <div class="status-row sort-item" @click="changeSort('status')">
            Статус
            <span class="sort-icon" :class="[{ active: sort.type === 'status' }, sort.direction]" />
          </div>
          <div class="auditor-row sort-item" @click="changeSort('auditor')">
            Аудитор
            <span class="sort-icon" :class="[{ active: sort.type === 'auditor' }, sort.direction]" />
          </div>
          <div class="type-row sort-item" @click="changeSort('type')">
            Вид проверки
            <span class="sort-icon" :class="[{ active: sort.type === 'type' }, sort.direction]" />
          </div>
          <div class="date-row sort-item" @click="changeSort('plan_date')">
            Дата начала
            <span class="sort-icon" :class="[{ active: sort.type === 'plan_date' }, sort.direction]" />
          </div>
          <div class="date-row sort-item" @click="changeSort('finish_date')">
            Дата завершения
            <span class="sort-icon" :class="[{ active: sort.type === 'finish_date' }, sort.direction]" />
          </div>
          <div class="date-row sort-item" @click="changeSort('sign_date')">
            Дата подписания
            <span class="sort-icon" :class="[{ active: sort.type === 'sign_date' }, sort.direction]" />
          </div>
          <div class="document-row">Документы</div>
        </div>
        <checklistItem
          v-for="(item, key) in organizationCheckLists"
          :key="key"
          :class="{ gray: key % 2 !== 0 }"
          :item="item"
        />
        <div class="pagination-container">
          <Paginate
            v-if="count / perPage > 1"
            :key="perPage"
            :page-count="Math.ceil(count / perPage)"
            :page-range="5"
            :margin-pages="2"
            :next-text="''"
            :prev-text="''"
            :click-handler="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NAlert } from 'naive-ui'
import Paginate from 'vuejs-paginate-next'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import checklistItem from '@/components/widgets/simple-components/items/checklistItem'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import paginationMixin from '@/components/mixins/paginationMixin'
import useTarifExtension from '@/composables/useTarifExtension'

export default {
  name: 'AuditionPage',
  components: { dropdownListWithHeader, nextButton, checklistItem, inputWithHeader, Paginate, NAlert },
  mixins: [paginationMixin],
  setup() {
    const { hasPermission } = useTarifExtension()
    return {
      hasPermission
    }
  },
  data() {
    return {
      sort: {
        type: 'sign_date',
        direction: 'desc'
      },
      selectedMedOrgId: false,
      filters: {
        status: '',
        startDate: '',
        stopDate: ''
      }
    }
  },
  computed: {
    organizationCheckLists() {
      let list = []
      for (let i = 0; i < this.$store.getters.organizationChecklists.length; i++) {
        let item = this.$store.getters.organizationChecklists[i]
        list.push(item)
      }
      return list
    },
    allStatuses() {
      let statuses = []
      statuses[0] = { text: 'Все', id: '' }
      for (let i = 0; i < this.$store.getters.checklistStatuses.length; i++) {
        statuses.push(this.$store.getters.checklistStatuses[i])
      }
      return statuses
    },
    organizationMedTypesPermission() {
      const { organizationMedTypes } = this.$store.getters
      let medTypes = []

      if (this.hasPermission(20)) {
        medTypes = organizationMedTypes
      } else if (this.hasPermission(21)) {
        medTypes.push(organizationMedTypes.find((el) => el?.text === 'Организация ВКК и БМД'))
      } else if (this.hasPermission(22)) {
        medTypes.push(organizationMedTypes.find((el) => el?.text === 'Стоматология'))
      } else if (this.hasPermission(27)) {
        medTypes.push(organizationMedTypes.find((el) => el?.text === 'Стационар'))
      } else if (this.hasPermission(28)) {
        medTypes.push(organizationMedTypes.find((el) => el?.text === 'Поликлиника'))
      } else if (this.hasPermission(29)) {
        medTypes.push(organizationMedTypes.find((el) => el?.text === 'Медицинская лаборатория'))
      } else if (this.hasPermission(30)) {
        medTypes.push(organizationMedTypes.find((el) => el?.text === 'Центр диализа'))
      } else if (this.hasPermission(31)) {
        medTypes.push(organizationMedTypes.find((el) => el?.text === 'Скорая медицинская помощь'))
      }

      return medTypes.filter((el) => Boolean(el))
    },
    hasAccess() {
      const hasAccess =
        this.hasPermission(20) ||
        this.hasPermission(21) ||
        this.hasPermission(22) ||
        this.hasPermission(27) ||
        this.hasPermission(28) ||
        this.hasPermission(29) ||
        this.hasPermission(30) ||
        this.hasPermission(31)

      return hasAccess
    }
  },
  mounted() {
    this.$store.dispatch('ORGANIZATION_UPDATE_ORGANIZATION_MEDTYPES')
    this.$store.dispatch('CHECKLIST_REFRESH_STATUSES')
    this.getChecklists()
  },
  methods: {
    changeSort(type) {
      let sort = {
        type: type,
        direction: this.sort.direction
      }
      if (type === this.sort.type) {
        sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc'
      } else {
        sort.direction = 'desc'
      }
      this.sort = sort
      this.changePage(this.page)
    },
    getChecklists() {
      this.$store
        .dispatch('CHECKLIST_GET_ORGANIZATION_CHECKLISTS', {
          offset: this.perPage * (this.page - 1),
          limit: this.perPage,
          startDate: this.filters.startDate,
          stopDate: this.filters.stopDate,
          sort: this.sort,
          status: this.filters.status
        })
        .then((res) => {
          if (res.data.ok === 1) {
            this.$store.dispatch('CHECKLIST_SET_ORGANIZATION_CHECKLISTS', res.data.data)
            this.count = res.data.count
          }
        })
    },
    changePage(val) {
      this.page = val
      this.getChecklists()
    },
    changeStartDate(val) {
      let currDate = val.target.value
      currDate = currDate.split('-')
      this.filters.startDate = new Date()
      this.filters.startDate.setFullYear(currDate[0], currDate[1] - 1, currDate[2])
      this.filters.startDate.setHours(0)
      this.filters.startDate.setMinutes(0)
      this.filters.startDate.setSeconds(0)
      this.filters.startDate.setMilliseconds(0)
      this.filters.startDate = this.filters.startDate.getTime()
      this.getChecklists()
    },
    changeStopDate(val) {
      let currDate = val.target.value
      currDate = currDate.split('-')
      this.filters.stopDate = new Date()
      this.filters.stopDate.setFullYear(currDate[0], currDate[1] - 1, currDate[2])
      this.filters.stopDate.setHours(23)
      this.filters.stopDate.setMinutes(59)
      this.filters.stopDate.setSeconds(59)
      this.filters.stopDate.setMilliseconds(999)
      this.filters.stopDate = this.filters.stopDate.getTime()
      this.getChecklists()
    },
    changeStatus(val) {
      this.filters.status = val.id
      this.getChecklists()
    },
    createCheckList() {
      if (!this.selectedMedOrgId) {
        return false
      } else {
        this.$router.push('/audition/create/?medtype=' + this.selectedMedOrgId)
      }
    },
    medTypeSelect(val) {
      this.selectedMedOrgId = val.id
    }
  }
}
</script>

<style scoped>
.sort-item {
  cursor: pointer;
}
.sort-icon.desc {
  transform: rotate(180deg);
  background-position-y: -3px;
}
.sort-icon.active {
  opacity: 1;
}
.sort-icon {
  opacity: 0;
  display: inline-block;
  background-size: contain;
  background-position-y: 4px;
  margin-left: 5px;
  width: 17px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 32 32'%3E%3Cpath d='M 16 8.59375 L 15.28125 9.28125 L 5.28125 19.28125 L 3.59375 21 L 28.40625 21 L 26.71875 19.28125 L 16.71875 9.28125 Z M 16 11.4375 L 23.5625 19 L 8.4375 19 Z' fill='white'/%3E%3C/svg%3E");
}
.filters {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}

.filter-delimiter {
  width: 23px;
  text-align: center;
}

.number-row {
  border-right: 1px solid #cccccc;
  width: 10%;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  flex: none;
}

.status-row {
  width: 12%;
  padding-top: 9px;
  padding-bottom: 9px;
  flex: none;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.auditor-row {
  width: 15%;
  flex: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.type-row {
  width: 15%;
  flex: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.date-row {
  width: 11%;
  flex: none;
  padding-left: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-right: 1px solid #cccccc;
}

.document-row {
  width: 15%;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  flex: none;
}

.list-header {
  margin-top: 20px;
  display: flex;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
}

.date-input {
  font-size: 16px;
  line-height: 11px;
  padding: 9px 15px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
}

.filter-header {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  padding-bottom: 9px;
}

.filter-inputs {
  display: flex;
  align-items: center;
}

.audit-list {
  padding: 23px 40px 100px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}

.audit-list__header {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  padding-bottom: 19px;
  color: #333333;
}

.dropdown {
  margin-right: 20px;
}
.content-header {
  font-size: 36px;
  color: #333333;
}
.content-header__management {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
}

.content-info {
  background-color: white;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}
</style>


<!-- АДАПТИВ -->
<style scoped lang="scss">
@media (max-width: 1200px) {
  .content-header {
    flex-direction: column;
    gap: 25px;
    font-size: 24px;
    padding-top: 30px;
  }

  .content-header__management {
    gap: 20px;

    .dropdown {
      margin-right: 0;
    }
  }

  .audit-list {
    padding: 23px 15px 100px;
    overflow-x: scroll;

    &__header {
      font-size: 20px;
    }

    .list {
      width: 1100px;
      
    }
  }
}
</style>