<template>
  <th
    class="next-table__th"
    :class="{ 'next-table__th_has-sorter': col.sortable }"
    :style="thStyle"
    @click="changeSort"
  >
    <div class="next-table__th-inner">
      <span class="next-table__th-title" style="width: 100%;">
        {{ col.title }}
      </span>
      <div v-if="col.sortable" class="next-table__th-sorter">
        <svg
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          :class="{ active: sortOrder === 'ASC' }"
        >
          <path
            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
          />
        </svg>
        <svg
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          :class="{ active: sortOrder === 'DESC' }"
        >
          <path
            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
          />
        </svg>
      </div>
    </div>
  </th>
</template>

<script>
export default {
  name: 'NextTableTh',
  props: {
    col: {
      type: Object,
      required: true
    }
  },
  emits: ['change:sort'],
  data() {
    return {
      sortOrder: this.col.sortOrder
    }
  },
  computed: {
    thStyle() {
      return {
        fontSize: this.col.fontSize,
        padding: this.col.padding,
        width: this.col.width,
        maxWidth: this.col.maxWidth,
        minWidth: this.col.minWidth,
        textAlign: this.col.center ? 'center' : null
      }
    }
  },
  methods: {
    changeSort() {
      if (!this.col.sortable) return

      this.sortOrder = !this.sortOrder ? 'ASC' : this.sortOrder === 'ASC' ? 'DESC' : ''

      this.$emit('change:sort', {
        field: this.col.key,
        order: this.sortOrder
      })
    }
  }
}
</script>
