<template>
  <div class="document-info">
    <div class="document-header">Данные о файле:</div>
    <inputWithHeader
      :start-text="file.name"
      style="padding-bottom: 25px"
      header="Название документа"
      :required="true"
      type="text"
      placeholder="Введите название документа"
      @inputHandler="setName"
    />
    <dropdownListWithHeader
      :start-item="{ id: file.category_id, text: file.text }"
      :search="false"
      :required="true"
      placeholder="Выберите категорию"
      header="Категория документа"
      :autoclose="true"
      :list="$store.getters.documentsCategory"
      style="padding-bottom: 12px"
      @selectHandler="selectCategory"
    />
    <div class="document-text" style="padding-bottom: 15px">
      <span>*</span> Поля отмеченные звёздочкой являются обязательными
    </div>
    <div v-if="file !== ''" class="content-buttons">
      <nextButton
        v-if="name !== '' && category !== ''"
        type="next"
        color="green"
        text="СОХРАНИТЬ"
        @clickHandler="changeDocument"
      />
      <nextButton v-else type="next" color="green" class="inactive" text="СОХРАНИТЬ" />
      <nextButton type="close" color="white" text="ОТМЕНИТЬ" @clickHandler="close" />
    </div>
  </div>
</template>

<script>
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
export default {
  name: 'EditDocument',
  components: { inputWithHeader, dropdownListWithHeader, nextButton },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: '',
      category: ''
    }
  },
  mounted() {
    this.name = this.file.name
    this.category = { id: this.file.category_id, text: this.file.text }
    this.$store.dispatch('DOCUMENTS_REFRESH_ALL_CATEGORIES')
  },
  methods: {
    close() {
      this.$parent.closeHandler()
    },
    async changeDocument() {
      let result = await this.$store.dispatch('DOCUMENTS_CHANGE_INFO', {
        document: this.file,
        name: this.name,
        category_id: this.category.id
      })
      if (result.data.ok === 1) {
        this.$parent.closeHandler()
      }
    },
    setName(val) {
      this.name = val
    },
    selectCategory(val) {
      this.category = val
    }
  }
}
</script>

<style scoped>
.document-header {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  padding-bottom: 20px;
  color: #333333;
}
.document-info {
  padding: 20px;
}
.content-buttons {
  display: flex;
  justify-content: space-between;
}
</style>
