<script setup>
import { computed, ref, h } from 'vue'
import { NModal, NForm, NFormItem, NButton } from 'naive-ui'
import EntitySelect from '@/components/common/EntitySelect'
import { serialize } from '@/utils/url'
import { isEmpty } from 'lodash'

defineExpose({
  openModal,
  closeModal
})

const defaultFormData = {
  med_type_id: null,
  process_id: null,
  action_category_id: null
}
const event = ref({})
const formData = ref({ ...defaultFormData })
const formRef = ref(null)
const isOpen = ref(false)
const isLoading = ref(false)
const actionCategoryIsRequired = ref(true)
const actionCategoryRequest = computed(
  () =>
    `refbook/get?${serialize(
      {
        subject: 'action-category',
        process_id: formData.value.process_id,
        object_id: event.value.object_id
      },
      true
    )}`
)
const processIdRequest = computed(() =>
  formData.value.med_type_id ? `/organization/get-processes-by-med-id?id=${formData.value.med_type_id}` : null
)
let callback = () => {}

const rules = computed(() => ({
  med_type_id: [{ required: true, message: 'Поле обязательно для заполнения' }],
  process_id: [{ required: true, message: 'Поле обязательно для заполнения' }],
  action_category_id: [{ required: actionCategoryIsRequired.value, message: 'Поле обязательно для заполнения' }]
}))

function openModal(action, cb) {
  event.value = action

  formData.value = {
    med_type_id: action.med_type_id,
    process_id: action.process_id,
    action_category_id: action.action_category_id
  }

  isOpen.value = true
  callback = cb
}

function closeModal() {
  isOpen.value = false
  formData.value = { ...defaultFormData }
}

function submit(e) {
  e.preventDefault()
  formRef.value?.validate(async (errors) => {
    if (errors) return

    try {
      isLoading.value = true
      await callback?.(formData.value)
      closeModal()
    } catch (e) {
      console.log(e)
    } finally {
      isLoading.value = false
    }
  })
}

function actionCategoryFetched(options) {
  actionCategoryIsRequired.value = !isEmpty(options)
}

function renderLabel(option) {
  return h('div', { class: 'whitespace-normal py-2 text-[13px]' }, option.label)
}

function renderTag({ option }) {
  return h('div', { class: 'truncate' }, option.label)
}
</script>

<template>
  <NModal
    v-model:show="isOpen"
    preset="card"
    title="Заполните обязательные поля"
    :style="{ width: '480px' }"
    :bordered="false"
    @update:show="closeModal"
  >
    <NForm ref="formRef" :model="formData" :rules="rules">
      <NFormItem label="Направление" path="med_type_id">
        <EntitySelect
          v-model:value="formData.med_type_id"
          request="/organization/get-organization-medtypes"
          placeholder="Направление"
        />
      </NFormItem>
      <NFormItem label="Раздел" path="process_id">
        <EntitySelect
          v-model:value="formData.process_id"
          :request="processIdRequest"
          placeholder="Раздел"
          value-field="process_id"
          label-field="process.text"
          :render-label="renderLabel"
          :render-tag="renderTag"
          :disabled="!formData.med_type_id"
        />
      </NFormItem>
      <NFormItem label="Событие" path="action_category_id">
        <EntitySelect
          v-model:value="formData.action_category_id"
          placeholder="Событие"
          :request="actionCategoryRequest"
          :disabled="!formData.process_id"
          label-field="name"
          :render-label="renderLabel"
          :render-tag="renderTag"
          @fetched="actionCategoryFetched"
        />
      </NFormItem>
    </NForm>
    <template #footer>
      <div class="d-flex justify-content-end">
        <NButton class="mr-2" secondary :disabled="isLoading" @click="closeModal">Отмена</NButton>
        <NButton type="primary" :loading="isLoading" @click="submit">Сохранить</NButton>
      </div>
    </template>
  </NModal>
</template>
