<template>
  <div class="list" v-if="list.length > 0">
    <div class="list-head">
      <div class="id-column list-column sort-item" @click="changeSort('id')">ID
        <span class="sort-icon" :class="[{active: sort.type === 'id'}, sort.direction]"></span>
      </div>
      <div class="action-column list-column sort-item" @click="changeSort('text')">Мероприятие
        <span class="sort-icon" :class="[{active: sort.type === 'text'}, sort.direction]"></span>
      </div>
      <div class="type-column list-column sort-item" @click="changeSort('type')">Тип мероприятия
        <span class="sort-icon" :class="[{active: sort.type === 'type'}, sort.direction]"></span>
      </div>
      <div class="commentary-column list-column sort-item" @click="changeSort('object')">Объект учёта
        <span class="sort-icon" :class="[{active: sort.type === 'object'}, sort.direction]"></span>
      </div>
      <div class="owner-column list-column sort-item" @click="changeSort('responsible')">Ответственный
        <span class="sort-icon" :class="[{active: sort.type === 'responsible'}, sort.direction]"></span>
      </div>
      <div class="date-column list-column sort-item" @click="changeSort('plan_date')">Плановая дата
        <span class="sort-icon" :class="[{active: sort.type === 'plan_date'}, sort.direction]"></span>
      </div>
      <div class="date-column list-column sort-item" @click="changeSort('date')">Фактическая дата
        <span class="sort-icon" :class="[{active: sort.type === 'date'}, sort.direction]"></span>
      </div>
      <div class="status-column list-column sort-item" @click="changeSort('status')">Статус
        <span class="sort-icon" :class="[{active: sort.type === 'status'}, sort.direction]"></span>
      </div>
    </div>
    <div class="list-content">
      <ActionListItem :class="{gray: key % 2 !== 0}" v-for="(item, key) in list" :key="key"
                      :item="item"></ActionListItem>
    </div>
  </div>
  <div class="list" v-else>Нет мероприятий</div>
</template>

<script>
import ActionListItem from '@/components/widgets/custom-components/lists/listItems/actionListItem'

export default {
  name: 'actionsList',
  components: { ActionListItem },
  props: {
    sort: {
      type: Object,
      default() {
        return {
          type: 'status',
          direction: 'desc',
        }
      }
    },
    list: {
      type: Array,
      required: true
    }
  },
  methods: {
    changeSort(type) {
      let sort = {
        type: type,
        direction: this.sort.direction,
      }
      if(type === this.sort.type) {
        sort.direction = this.sort.direction === 'asc'? 'desc': 'asc';
      } else {
        sort.direction = 'desc';
      }
      this.$emit('changeSort', sort);
    }
  }
}
</script>

<style scoped>
.sort-item {
  cursor: pointer;
}
.sort-icon.desc {
  transform: rotate(180deg);
  background-position-y: -3px;
}
.sort-icon.active {
  opacity: 1;
}
.sort-icon {
  opacity: 0;
  display: inline-block;
  background-size: contain;
  background-position-y: 4px;
  margin-left: 5px;
  width: 17px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 32 32'%3E%3Cpath d='M 16 8.59375 L 15.28125 9.28125 L 5.28125 19.28125 L 3.59375 21 L 28.40625 21 L 26.71875 19.28125 L 16.71875 9.28125 Z M 16 11.4375 L 23.5625 19 L 8.4375 19 Z' fill='white'/%3E%3C/svg%3E");
}
.list-column {
  border-right: 1px solid white;
  width: 100%;
  font-size: 14px;
  padding: 10px 10px;
  color: white;
  text-align: left;
}

.date-column {
  width: 160px;
  flex: none;
  text-align: center;
}

.status-column {
  width: 130px;
  flex: none;
}

.owner-column {
  width: 175px;
  flex: none;
}

.commentary-column {
  width: 175px;
  flex: none;
}

.type-column {
  width: 175px;
  flex: none;
}

.action-column {
  width: 100%;
}

.list-head {
  display: flex;
  width: 100%;
  background: linear-gradient(180deg, #37C489 0%, #00CCCC 100%);
}

.id-column {
  width: 75px;
  flex: none;
}

.list {
  width: 100%;
  min-width: 1200px;
}

</style>