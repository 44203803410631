<template>
  <table class="table">
    <tbody>
      <tr v-for="(row, i) in tableData" :key="`row_${row.title}_${i}`">
        <td>
          <slot name="title" :row="row">
            <div :class="{ 'font-weight-bold': row.bolder }">
              {{ row.title || placeholder }}
            </div>
          </slot>
        </td>
        <td>
          <slot name="value" :row="row">
            <template v-if="!row.children">
              <router-link v-if="row.url" :to="row.url">{{ row.value || placeholder }}</router-link>
              <div v-else v-html="row.value || placeholder" />
            </template>
            <div v-else class="d-flex" :class="{ 'justify-content-between': row.children?.length > 1 }">
              <div class="mr-5">
                <router-link v-if="row.url" :to="row.url">{{ row.value || placeholder }}</router-link>
                <template v-else>{{ row.value || placeholder }}</template>
              </div>
              <div
                v-for="(child, _i) in row.children"
                :key="`child_${child.title}_${_i}`"
                class="d-flex align-items-center"
                :class="{
                  'text-nowrap': child.nowrap,
                  'flex-wrap': !child.nowrap,
                  'mr-5': _i < row.children.length - 1
                }"
              >
                <span class="mr-2" :class="{ 'font-weight-bold': child.bolder }"> {{ child.title }}: </span>
                <StatusIcon v-if="child.status" :status="child.status" />
                <template v-else>{{ child.value || placeholder }}</template>
              </div>
            </div>
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import StatusIcon from '@/components/widgets/simple-components/status-icon/StatusIcon'

export default {
  name: 'TableInfo',
  components: { StatusIcon },
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: '—'
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  margin-bottom: 0;

  tr {
    &:first-child {
      td {
        border-top: none;
      }
    }
  }

  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    vertical-align: middle;

    &:first-child {
      width: 15rem;
      padding-left: 0;
    }
  }
}

@media (max-width: 800px) {
  .table {
    font-size: 15px;
  }
}
</style>
