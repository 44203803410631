<template>
  <NAlert v-if="!hasPermission" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
    Для получения полного доступа необходимо сменить тариф
  </NAlert>

  <div class="content-block" :class="{ 'disabled-by-tarif': !hasPermission }">
    <div class="content-header">Выбор показателей медицинской деятельности</div>
    <div class="content-info">
      <vkkItem v-for="item in indicators" :key="item.id" :item="item" @click-handler="selectIndicator(item)" />
    </div>
  </div>
</template>

<script>
import vkkItem from '@/components/widgets/simple-components/items/vkkItem'
import { NAlert } from 'naive-ui'

export default {
  name: 'VkkInformation',
  components: { vkkItem, NAlert },
  props: {
    hasPermission: {
      type: Boolean,
    }
  },
  data() {
    return {
      organization: this.$store.getters['currentOrganization']
    }
  },
  computed: {
    indicators() {
      let currentItems = this.$store.getters['organizationIndicators']
      let items = []
      for (let i = 0; i < this.$store.getters['indicators'].length; i++) {
        items.push(this.$store.getters['indicators'][i])
        for (let n = 0; n < currentItems.length; n++) {
          if (this.$store.getters['indicators'][i].id == currentItems[n].indicator_id) {
            items[i].active = true
          }
        }
      }
      return items
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async selectIndicator(item) {
      if (item.pause) {
        return
      }
      item.pause = true
      if (item.active === true) {
        await this.$store.dispatch('VKK_REMOVE_INDICATOR', {
          organization_id: this.organization.id,
          indicator_id: item.id
        })
        item.active = false
        item.pause = false
      } else {
        await this.$store.dispatch('VKK_CREATE_INDICATOR', {
          organization_id: this.organization.id,
          indicator_id: item.id
        })
        item.active = true
        item.pause = false
      }
    },
    init() {
      this.$store.dispatch('VKK_UPDATE_INDICATORS')
      this.$store.dispatch('ORGANIZATION_UPDATE_ORGANIZATION_INDICATORS', this.organization.id)
    }
  }
}
</script>

<style scoped>
.content-header {
  font-size: 23px;
  line-height: 31px;
  padding-bottom: 23px;
}
</style>
