<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { NButton, NH1 } from 'naive-ui'
import { storeToRefs } from 'pinia'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import CreateForm from './CreateForm'
import { useRisksStore } from '@/store/risks'

const risksStore = useRisksStore()
const { isLoading, isError, item: risk } = storeToRefs(risksStore)

const route = useRoute()
const router = useRouter()
const riskId = computed(() => route.params.id)

const formRef = ref(null)

onMounted(() => {
  // if (route?.query?.requirement && route?.query?.checklist) {
  //   fetchChecklist()
  // }
  risksStore.fetchOne(riskId.value)
})

// async function fetchChecklist() {
//   const res = await checklistsStore.fetchOne(route.query.checklist)
//   const checklist = res.data.data
//   const commentary = checklist.requirements?.find(
//     (item) => item?.organization_requirement?.id === parseInt(route.query?.requirement)
//   )?.commentary

//   action.value = {
//     checklist,
//     commentary,
//     medtype: checklist.medtype,
//     action: {
//       assurance: checklist.assurance,
//       assurance_id: checklist.assurance_id,
//       auditor: checklist.assurance_user,
//       auditor_id: checklist.assurance_user_id
//     }
//   }
// }

async function save() {
  console.log('save')
  formRef.value?.validate((errors, values) => {
    console.log('%c LOG: ', 'background: #9488ea; color: #f1f1f1; font-size: 12px; border-radius: 4px', errors)
    if (errors) return

    risksStore.update(riskId.value, values).then((res) => {
      router.push('/risks/' + res.data.id)
    })
  })
}

function cancel() {
  router.back()
  // router.push(route.query?.checklist ? `/checklist/${route.query.checklist}` : '/actions/')
}
</script>

<template>
  <div class="max-w-6xl mx-auto">
    <div class="page-header">
      <div class="page-header__left">
        <NH1 class="mb-0">Редактирование паспорта риска</NH1>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <NButton type="primary" :loading="isSaving" @click="save">Сохранить</NButton>
          <NButton :disabled="isSaving" strong secondary @click="cancel">Отмена</NButton>
        </div>
      </div>
    </div>
    <div class="content">
      <NextAlert v-if="isError && !isLoading" class="mb-4" type="error" :text="error" />
      <SpinLoader v-else-if="isLoading" class="py-5" size="2xl" />
      <div v-else class="max-w-200">
        <CreateForm ref="formRef" :data="risk" is-edit />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  padding: 40px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}
</style>
