<template>
  <div class="calendar">
    <div class="calendar-date">{{ months[month] }} {{ year }}</div>
    <div class="calendar-days">
      <div class="weekdays">
        <div
          v-for="(d, key) in day"
          :key="key"
          class="weekday text-secondary text-sm text-uppercase"
          :class="{ 'font-weight-bold': key >= 5 }"
        >
          {{ d }}
        </div>
      </div>
      <div v-for="(week, key) in currentDates" :key="key" class="weekdays">
        <div
          v-for="(day, index) in week"
          :key="index"
          class="weekday"
          :class="getDayClasses(day)"
          @click="openControl(day)"
        >
          {{ day.index }}
          <div v-if="day.startControl" class="start-point point" />
          <div v-if="day.stopControl" class="stop-point point" />
          <div v-if="day.message && day.message != ''" class="day-message">{{ day.message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventsCalendarMonth',
  props: {
    month: {
      type: Number,
      required: true
    },
    year: {
      type: Number,
      default: new Date().getFullYear()
    }
  },
  data() {
    return {
      dFirstMonth: '1',
      day: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ],
      date: new Date(),
      currentDates: []
    }
  },
  mounted() {
    this.calendar()
  },
  methods: {
    openControl(day) {
      if (day.control) {
        this.$router.push('/control/' + day.control.id)
      }
    },
    makePoints(controls) {
      for (let i = 0; i < this.currentDates.length; i++) {
        let week = this.currentDates[i]
        for (let n = 0; n < week.length; n++) {
          if (week[n] !== '') {
            week[n] = this.modifyDay(week[n], controls)
          }
        }
      }
    },
    getDateString(timestamp) {
      let current_date = new Date(timestamp)
      return current_date.getMonth() + 1 + '-' + current_date.getDate() + '-' + current_date.getFullYear()
    },
    modifyDay(day, controls) {
      let dateTime = new Date(this.year, this.month, day.index)
      let timestamp = dateTime.getTime()
      day.startControl = false
      day.stopControl = false
      day.commission = false
      day.message = ''
      for (let i = 0; i < controls.length; i++) {
        let start_date = new Date(controls[i].start_date * 1000)
        start_date = new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate())
        if (start_date.getTime() === timestamp) {
          day.startControl = true
          day.control = controls[i]
          day.message = 'Начало: ' + controls[i].assurance.text + '. Раздел: ' + controls[i].process.text
        }
        let finish_date = new Date(controls[i].finish_date * 1000)
        finish_date = new Date(finish_date.getFullYear(), finish_date.getMonth(), finish_date.getDate())
        if (finish_date.getTime() === timestamp) {
          day.stopControl = true
          day.control = controls[i]
          day.message = 'Завершение: ' + controls[i].assurance.text + '. Раздел: ' + controls[i].process.text
        }
        let commission_date = new Date(controls[i].commission_date * 1000)
        commission_date = new Date(commission_date.getFullYear(), commission_date.getMonth(), commission_date.getDate())
        if (commission_date.getTime() === timestamp) {
          day.commission = true
          day.control = controls[i]
          day.message = 'Комиссия: ' + controls[i].assurance.text + '. Раздел: ' + controls[i].process.text
        }
      }
      return day
    },
    async getAllControls() {
      const res = await this.$api.get('/control/get-by-date', {
        params: {
          month: this.month + 1,
          year: this.year
        }
      })

      if (res.data.ok === 1) {
        this.makePoints(res.data.data)
      }
    },
    checkToday(date) {
      let today = new Date()
      today.setHours(0)
      today.setMinutes(0)
      today.setSeconds(0)
      today.setMilliseconds(0)
      if (date.getTime() === today.getTime()) {
        return true
      } else {
        return false
      }
    },
    dayChange() {
      if (this.dFirstMonth == 0) {
        this.day = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
      } else {
        this.day = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
      }
    },
    calendar() {
      var days = []
      var week = 0
      days[week] = []
      var dlast = new Date(this.year, this.month + 1, 0).getDate()
      for (let i = 1; i <= dlast; i++) {
        let tmpDate = new Date(this.year, this.month, i)
        let weekend = false
        if (tmpDate.getDay() === 0 || tmpDate.getDay() === 6) {
          weekend = true
        }
        if (new Date(this.year, this.month, i).getDay() != this.dFirstMonth) {
          let a = { index: i, weekend: weekend }
          days[week].push(a)
        } else {
          week++
          days[week] = []
          let a = { index: i, weekend: weekend }
          days[week].push(a)
        }
      }
      if (days[0].length > 0) {
        for (let i = days[0].length; i < 7; i++) {
          days[0].unshift('')
        }
      }
      this.dayChange()
      this.getAllControls()
      this.currentDates = days
    },
    getDayClasses(day) {
      return {
        pointer: day.startControl || day.stopControl || day.commission,
        commission: day.commission,
        weekend: day.weekend,
        today: this.checkToday(new Date(this.year, this.month, day.index))
      }
    }
  }
}
</script>

<style scoped>
.weekday:hover .day-message {
  display: block;
}

.day-message {
  font-weight: 400;
  display: none;
  position: absolute;
  width: 250px;
  z-index: 9;
  box-shadow: inset 0px -3px 9px rgb(0 0 0 / 13%);
  border-radius: 6px;
  bottom: 120%;
  padding: 10px;
  background-color: white;
}

.stop-point {
  right: 2px;
  background-color: #00cccc;
}

.start-point {
  left: 2px;
  background-color: #37c489;
}

.point {
  top: 2px;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.weekday.commission {
  border-color: #d65959;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}

.weekday {
  position: relative;
}

.calendar-days {
  height: 210px;
  margin-bottom: 20px;
}

.calendar-date {
  margin-bottom: 10px;
  text-align: center;
}

.weekday.today {
  border-color: #37c389;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}

.weekday.weekend {
  font-weight: 600;
}

.weekday {
  border: 1px solid transparent;
  display: flex;
  border-radius: 3px;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.weekdays {
  display: flex;
}
</style>
