<template>
  <div>
    <div class="d-flex flex-wrap mb-3">
      <OrganizationInformationItem v-if="data?.checklist" icon="white-number-icon" header="№ отчёта">
        <router-link :to="'/checklist/' + data.checklist?.number">{{ data.checklist?.number }}</router-link>
      </OrganizationInformationItem>

      <OrganizationInformationItem v-if="data?.action?.risk_id" icon="white-number-icon" header="№ Риска">
        <router-link :to="'/risks/' + data?.action?.risk_id">{{ data?.action?.risk_id }}</router-link>
      </OrganizationInformationItem>

      <OrganizationInformationItem v-if="data?.action?.inevent_id" icon="white-number-icon" header="№ НС">
        <router-link :to="'/incidents/' + data?.action?.inevent_id">{{ data?.action?.inevent_id }}</router-link>
      </OrganizationInformationItem>

      <OrganizationInformationItem v-if="data?.requirement?.id" icon="white-number-icon" header="№ требования">
        <router-link :to="'/requirement/' + data?.requirement?.id">{{ data?.requirement?.base_id }}</router-link>
      </OrganizationInformationItem>

      <OrganizationInformationItem icon="white-list-icon" header="Объект учёта" :text="action.object.text" />
      <OrganizationInformationItem icon="white-search-icon" header="Вид проверки" :text="action.assurance.text" />
      <OrganizationInformationItem
        icon="white-clock-icon"
        header="Дата закрытия"
        :text="$helper.makeDate(action.finish_date)"
      />
      <OrganizationInformationItem header="Статус" icon="white-checkmark-icon" :text="action.status.text" />
      <OrganizationInformationItem header="Аудитор/автор" icon="white-checkmark-icon" :text="action?.auditor?.name" />
    </div>
    <div class="border-2 border-primary mb-5 px-4">
      <TableInfo :table-data="primaryTableData" />
    </div>
    <TableInfo :table-data="tableData" />
  </div>
</template>

<script>
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import TableInfo from '@/components/widgets/simple-components/table-info/TableInfo'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import { isEmpty } from 'lodash'

export default {
  name: 'MainTab',
  components: { NextAlert, TableInfo, OrganizationInformationItem },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: this.data.action
    }
  },
  computed: {
    tableData() {
      if (isEmpty(this.action)) return []

      const data = [
        {
          title: 'Направление',
          value: this.data?.medtype?.text || this.action?.process?.medtype
        },
        {
          title: 'Раздел',
          value: this.data.process?.text || this.action?.process?.text,
          url: this.getProcessUrl()
        }
      ]

      if (this.data?.requirement) {
        data.unshift({
          title: 'Требование',
          value: this.data?.requirement?.text,
          url: this.data.requirement?.id ? `/requirement/${this.data.requirement?.id}` : null
        })
      }

      return data
    },
    primaryTableData() {
      if (isEmpty(this.action)) return []

      return [
        {
          title: 'Наименование мероприятия',
          value: this.action.text,
          bolder: true
        },
        {
          title: 'Описание',
          value: this.data.checklist_requirement?.commentary || this.data?.action?.capture,
          bolder: true
        },
        {
          title: 'Событие',
          value: this.action?.action_category?.name,
          bolder: true
        },
        {
          title: 'Плановая дата закрытия',
          value: this.$helper.makeDate(this.action.plan_date),
          bolder: true,
          children: [
            {
              title: 'Ответственный',
              value: this.action?.responsible?.name,
              bolder: true
            },
            {
              title: 'Тип мероприятия',
              value: this.data.action?.type?.text,
              bolder: true
            }
          ]
        }
      ]
    }
  },
  watch: {
    data(v) {
      const process_id = this.action.process_id

      this.action = {
        ...v?.action,
        process: this.action.process,
        action_category: this.action.action_category
      }

      if (v.action.process_id && process_id !== v.action.process_id) {
        this.fetchProcess()
      }

      if (v.action.action_category_id) {
        this.fetchActionCategory()
      }
    }
  },
  mounted() {
    if (this.action.process_id) {
      this.fetchProcess()
    }
    if (this.action.action_category_id) {
      this.fetchActionCategory()
    }
  },
  methods: {
    async fetchProcess() {
      const res = await this.$api.get(`process/get?id=${this.action.process_id}`)
      this.action.process = res.data.data
    },
    async fetchActionCategory() {
      const res = await this.$api.get(`refbook/get?id=${this.action.action_category_id}`)
      this.action.action_category = res.data.data[0]
    },
    getProcessUrl() {
      const id = this.data.process?.id || this.action.process_id
      return id ? `/process/${id}` : null
    }
  }
}
</script>

<style lang="scss" scoped>
.information-item {
  padding-right: 1.5rem;
  margin-bottom: 2rem;
  width: auto;

  &:last-child {
    padding-right: 0;
  }
}
</style>
