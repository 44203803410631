<template>
  <div class="dropdown">
    <slot name="header" />
    <div class="dropdown-container">
      <div class="dropdown-searchblock">
        <div class="input-container">
          <div v-if="header" class="input__header">
            {{ header }}&nbsp;
            <span v-if="required">*</span>
          </div>

          <div class="input-block" :style="{ 'pointer-events': disabled ? 'none' : 'all' }">
            <input
              v-if="search"
              v-model="text"
              :autocomplete="false"
              type="text"
              class="input-field"
              :placeholder="placeholder"
              :disabled="disabled"
              @keypress.enter="enterHandler"
              @input="inputHandler"
            >

            <div v-else class="input-field pointer" @click="openDropdownHandler">
              <span v-if="selectedItem.text">{{ selectedItem.text }}</span>
              <span v-else class="placeholder">{{ placeholder }}</span>
            </div>

            <div
              v-if="!disabled"
              class="down-icon icon pointer"
              :class="{ reverse: openDropdown }"
              @click="openDropdownHandler"
            />
          </div>
        </div>
      </div>
      <div v-if="openDropdown" class="dropdown-backdrop" @click="openDropdown = false" />
      <div v-if="openDropdown" class="dropdown-list">
        <div v-if="list.length === 0" class="no-item">В данном списке пусто</div>
        <dropdownItem
          v-for="(item, key) in list"
          :id="item.id || item.text"
          :key="key"
          :text="item.text"
          @click-handler="selectHandler(item)"
          @click="selectedItem = item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import dropdownItem from '@/components/widgets/simple-components/inputs/dropdown/dropdownItem'

export default {
  name: 'DropdownWithHeader',
  components: { dropdownItem, inputWithHeader },
  props: {
    required: Boolean,
    search: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autoclose: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    startItem: {
      default() {
        return false
      }
    },
    list: {
      type: Array,
      required: true
    },
    header: String
  },
  data() {
    return {
      text: '',
      openDropdown: false,
      selectedItem: {}
    }
  },
  mounted() {
    if (this.startItem) {
      this.selectedItem = this.startItem
      this.text = this.selectedItem.text
    }
  },
  methods: {
    openDropdownHandler() {
      this.openDropdown = !this.openDropdown
      if (this.openDropdown) {
        this.$emit('openDropdownHandler', this.text)
      }
    },
    enterHandler() {
      this.$emit('enterHandler', this.text)
      this.openDropdown = true
    },
    inputHandler() {
      this.$emit('inputHandler', this.text)
      this.openDropdown = true
    },
    selectHandler(val) {
      this.openDropdown = false
      if (val.text) {
        this.text = val.text
      }
      this.$emit('selectHandler', val)
    }
  }
}
</script>

<style scoped>
.dropdown {
  display: flex;
  align-items: center;
}

.dropdown-container {
  width: 100%;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  /* max-height: 200px; */
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid rgb(224, 224, 230);
  border-radius: 4px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.no-item {
  font-size: 14px;
  color: rgba(51, 51, 51, 0.87);
}

.placeholder {
  color: #cccccc;
}

.reverse {
  transform: rotate(180deg);
}

.input-block {
  width: 100%;
}

.input__header {
  display: flex;
  padding-bottom: 9px;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
}

.input-field {
  font-size: 14px;
  display: flex;
  align-items: center;
  height: auto;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 40px;
  background-color: #ffffff;
  width: 100%;
  border: 1px solid rgb(224, 224, 230);
  box-sizing: border-box;
  border-radius: 4px;
  transition: ease all 0.3s;
}

.input-field:hover,
.input-field:focus {
  border-color: #3eceac;
}

.input__header span {
  color: #1cc8ab;
}

.down-icon {
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
}

.input-block {
  display: flex;
  position: relative;
}
</style>
