<template>
  <div class="content-block">
    <div class="content-header">
      Журнал заседаний
      <div class="content-header__management">
        <div class="dropdown">
          <p>Тип заседания</p>
          <NSelect
            size="large"
            clearable
            style="width: 280px"
            :options="inspectionTypes"
            :on-update:value="inspectionTypeSelect"
          />
        </div>
        <nextButton
          :class="{ inactive: !Number.isInteger(selectedInspectionType) }"
          text="Запланировать заседание"
          :color="'green'"
          @click-handler="createInspection"
        />
      </div>
    </div>

    <div class="audit-list" :class="{ 'disabled-by-tarif': !hasAccess }">
      <NAlert v-if="!hasAccess" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
        Для получения полного доступа необходимо сменить тариф
      </NAlert>

      <div class="audit-list__header">Заседания</div>
      <div class="filters">
        <div class="mr-4">
          <div class="filter-header">Период</div>
          <div class="filter-inputs">
            <input class="date-input" type="date" @change="changeStartDate" />
            <div class="filter-delimiter">-</div>
            <input class="date-input" type="date" @change="changeStopDate" />
          </div>
        </div>
        <div class="mr-4">
          <div class="filter-header">Статус</div>
          <NSelect
            size="large"
            clearable
            style="width: 280px"
            :value="filters.status"
            :options="inspectionStatuses"
            :on-update:value="changeStatus"
          />
        </div>

        <div class="mr-4">
          <div class="filter-header">Тип заседания</div>
          <NSelect
            size="large"
            clearable
            style="width: 280px"
            :value="filters.type"
            :options="inspectionTypes"
            :on-update:value="changeInspectionType"
          />
        </div>
      </div>

      <div v-show="isLoading" class="flex justify-content-center">
        <NSpin class="my-5" />
      </div>

      <div v-if="!isLoading" class="list">
        <div class="list-header">
          <div class="number-row sort-item" @click="changeSort('number')">
            №
            <span class="sort-icon" :class="[{ active: sort.type === 'number' }, sort.direction]" />
          </div>
          <div class="status-row sort-item" @click="changeSort('status')">
            Статус
            <span class="sort-icon" :class="[{ active: sort.type === 'status' }, sort.direction]" />
          </div>
          <div class="auditor-row sort-item" @click="changeSort('auditor')">
            Очередность
            <span class="sort-icon" :class="[{ active: sort.type === 'auditor' }, sort.direction]" />
          </div>
          <div class="type-row sort-item" @click="changeSort('type')">
            Тип заседания
            <span class="sort-icon" :class="[{ active: sort.type === 'type' }, sort.direction]" />
          </div>
          <div class="date-row sort-item" @click="changeSort('meeting_date')">
            Дата заседания
            <span class="sort-icon" :class="[{ active: sort.type === 'meeting_date' }, sort.direction]" />
          </div>
        </div>

        <inspectionItem
          v-for="(item, key) in inspectionLists"
          :key="key"
          :class="{ gray: key % 2 !== 0 }"
          :item="item"
        />
        <div class="pagination-container">
          <Paginate
            v-if="count / perPage > 1"
            :key="perPage"
            :page-count="Math.ceil(count / perPage)"
            :page-range="5"
            :margin-pages="2"
            :next-text="''"
            :prev-text="''"
            :click-handler="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { NAlert, NSpin, NSelect } from 'naive-ui'
import Paginate from 'vuejs-paginate-next'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import inspectionItem from '@/components/widgets/simple-components/items/inspectionItem'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import paginationMixin from '@/components/mixins/paginationMixin'
import useTarifExtension from '@/composables/useTarifExtension'

export default {
  components: { dropdownListWithHeader, nextButton, inspectionItem, inputWithHeader, Paginate, NAlert, NSpin, NSelect },
  mixins: [paginationMixin],
  setup() {
    const { hasPermission } = useTarifExtension()
    return {
      hasPermission
    }
  },
  data() {
    return {
      sort: {
        type: 'meeting_date',
        direction: 'desc'
      },
      selectedInspectionType: null,
      filters: {
        type: undefined,
        status: undefined,
        startDate: '',
        stopDate: ''
      }
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => {
        return state.inspection.isLoading
      }
    }),
    inspectionLists() {
      return this.$store.getters.inspectionList
    },
    inspectionStatuses() {
      const transformedStatuses = this.$store.getters.inspectionStatuses.map((status) => ({
        ...status,
        label: status.name,
        value: status.id
      }))

      return transformedStatuses
    },
    inspectionTypes() {
      const transformedTypes = this.$store.getters.inspectionTypes.map((type) => ({
        ...type,
        label: type.name,
        value: type.id
      }))

      return transformedTypes
    },
    hasAccess() {
      const hasAccess = true

      return hasAccess
    }
  },
  mounted() {
    this.getInspectionList()
    this.getInspectionStatuses()
    this.getInspectionTypes()
  },
  methods: {
    changeSort(type) {
      let sort = {
        type: type,
        direction: this.sort.direction
      }
      if (type === this.sort.type) {
        sort.direction = this.sort.direction === 'asc' ? 'desc' : 'asc'
      } else {
        sort.direction = 'desc'
      }
      this.sort = sort
      this.changePage(this.page)
    },
    getInspectionList() {
      this.$store.dispatch('INSPECTION_GET_LIST', {
        offset: this.perPage * (this.page - 1),
        limit: this.perPage,
        startDate: this.filters.startDate,
        stopDate: this.filters.stopDate,
        sort: this.sort,
        status_id: this.filters.status,
        type_id: this.filters.type
      })
    },
    getInspectionStatuses() {
      this.$store.dispatch('INSPECTION_GET_STATUSES', {})
    },
    getInspectionTypes() {
      this.$store.dispatch('INSPECTION_GET_TYPES', {})
    },
    changePage(val) {
      this.page = val
      this.getInspectionList()
    },
    changeStartDate(val) {
      let currDate = val.target.value
      currDate = currDate.split('-')
      this.filters.startDate = new Date()
      this.filters.startDate.setFullYear(currDate[0], currDate[1] - 1, currDate[2])
      this.filters.startDate.setHours(0)
      this.filters.startDate.setMinutes(0)
      this.filters.startDate.setSeconds(0)
      this.filters.startDate.setMilliseconds(0)
      this.filters.startDate = this.filters.startDate.getTime()
      this.getInspectionList()
    },
    changeStopDate(val) {
      let currDate = val.target.value
      currDate = currDate.split('-')
      this.filters.stopDate = new Date()
      this.filters.stopDate.setFullYear(currDate[0], currDate[1] - 1, currDate[2])
      this.filters.stopDate.setHours(23)
      this.filters.stopDate.setMinutes(59)
      this.filters.stopDate.setSeconds(59)
      this.filters.stopDate.setMilliseconds(999)
      this.filters.stopDate = this.filters.stopDate.getTime()
      this.getInspectionList()
    },
    changeStatus(val) {
      this.filters.status = val
      this.getInspectionList()
    },
    changeInspectionType(val) {
      this.filters.type = val
      this.getInspectionList()
    },
    createInspection() {
      if (Number.isInteger(this.selectedInspectionType)) {
        this.$router.push('/inspection/create/?type=' + this.selectedInspectionType)
      }
    },
    inspectionTypeSelect(val) {
      this.selectedInspectionType = val
    }
  }
}
</script>

<style scoped lang="scss">
.sort-item {
  cursor: pointer;
}
.sort-icon.desc {
  transform: rotate(180deg);
  background-position-y: -3px;
}
.sort-icon.active {
  opacity: 1;
}
.sort-icon {
  opacity: 0;
  display: inline-block;
  background-size: contain;
  background-position-y: 4px;
  margin-left: 5px;
  width: 17px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 32 32'%3E%3Cpath d='M 16 8.59375 L 15.28125 9.28125 L 5.28125 19.28125 L 3.59375 21 L 28.40625 21 L 26.71875 19.28125 L 16.71875 9.28125 Z M 16 11.4375 L 23.5625 19 L 8.4375 19 Z' fill='white'/%3E%3C/svg%3E");
}
.filters {
  display: flex;
}

.filter-delimiter {
  width: 23px;
  text-align: center;
}

.number-row {
  border-right: 1px solid #cccccc;
  width: 15%;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  flex: none;
}

.status-row {
  width: 22%;
  padding-top: 9px;
  padding-bottom: 9px;
  flex: none;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.auditor-row {
  width: 21%;
  flex: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.type-row {
  width: 21%;
  flex: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.date-row {
  width: 21%;
  flex: none;
  padding-left: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-right: 1px solid #cccccc;
}

.list {
  width: 100%;
  max-width: 1300px;
}

.list-header {
  margin-top: 20px;
  display: flex;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
}

.date-input {
  font-size: 16px;
  line-height: 11px;
  padding: 9px 15px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
}

.filter-header {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #666666;
  padding-bottom: 9px;
}

.filter-inputs {
  display: flex;
  align-items: center;
}

.audit-list {
  padding: 23px 40px 100px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}

.audit-list__header {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  padding-bottom: 19px;
  color: #333333;
}

.dropdown {
  margin-right: 20px;

  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.content-header {
  font-size: 36px;
  color: #333333;
}
.content-header__management {
  display: flex;
  align-items: flex-end;
}

.content-info {
  background-color: white;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}
</style>
