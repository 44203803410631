<template>
  <div class="item" :class="[type, { full: openFullName }]">
    <div class="id-column">
      <router-link :to="'/requirement/' + item.requirement.id">{{ item.requirement.base_id }}</router-link>
    </div>
    <div class="requirement-column" @click="openFullName = !openFullName">
      <div class="requirement-text" :class="{ 'font-weight-bold': item.requirement?.component_criteria_id === 1 }">
        <span v-if="item.requirement?.component_criteria_id === 1" class="text-danger" title="Обязательное требование">
          *
        </span>
        {{ item.requirement.text }}
      </div>
    </div>
    <div class="status-column">
      <div
        v-if="type === 'edit' || type === 'finished'"
        class="status-icon pointer"
        :class="'status-' + item.status_id + '-icon'"
        :title="item.status.text"
        @click="changeStatus"
      />
      <div v-else class="status-icon" :title="item.status" :class="'status-' + item.status_id + '-icon'" />
    </div>
    
    <div class="commentary-column" @click="onCommentaryClick">
      <div class="requirement-text">
        {{ commentary?.slice(0, 27) }}{{ commentary?.length > 27 ? '...' : '' }}
      </div>
    </div>
    <div class="events-column" @click="openFullName = !openFullName">
      <router-link class="events-text" :to="actionLink" :title="actionText">{{ actionText }}</router-link>
      <div v-if="item.another_action && item.another_action.checklist" class="warning">
        <div class="warning-icon icon" :class="{ complete: item.another_action.status.id === 5 }">!</div>
        <div class="warning-text">
          Мероприятие <a target="_blanc" :href="'/actions/' + item.another_action.id">{{ item.another_action.id }}</a>
          {{ ' Статус: ' }}
          <span class="status-text" :class="'status-' + item.another_action.status.id">{{
            item.another_action.status.text
          }}</span>
          В чеклисте:
          <a target="_blanc" :href="'/checklist/' + item.another_action.checklist.number">
            {{ item.another_action.checklist.number }}
          </a>
        </div>
      </div>
    </div>

    <NModal
      v-model:show="showCommentaryModal"
    >
      <NCard
        :bordered="false"
        style="width: 600px"
        size="huge"
        role="dialog"
        aria-modal="true"
      >
        <p>Комментарий</p>
        <textarea
          v-model="commentary"
          :disabled="!['finished','edit'].includes(type)"
          rows="6"
          style="width: 100%; border: 2px solid #e9e9e9; border-radius: 4px; padding: 8px 12px;"
          class="commentary-column"
          spellcheck
          @focusout="setCommentary"
        />
      </NCard>
    </NModal>
  </div>
</template>

<script>
import { NCard, NModal, NPopover } from 'naive-ui'

export default {
  name: 'RequirementListItem',
  components: { NModal, NCard, NPopover },
  props: {
    type: {
      type: String,
      default() {
        return 'edit'
      }
    },
    checklist: {
      type: Object,
      default() {
        return {}
      }
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      commentary: '',
      allStatuses: this.$store.getters['allStatuses'],
      openStatuses: false,
      updateTimeout: '',
      openFullName: false,
      openedCreateEvent: false,
      showCommentaryModal: false
    }
  },
  computed: {
    actionText() {
      if (this.item.action == null) {
        if (this.checklist.status_id === 3) {
          return ''
        } else {
          return 'Добавить'
        }
      } else {
        return this.item.action.id + ' ' + this.item.action.text
      }
    },
    actionLink() {
      var link = ''

      if (this.item.action == null) {
        link =
          '/actions/create/?requirement=' + this.item.organization_requirement?.id + 
          '&checklist=' + this.checklist?.id + 
          '&assurance_id=' + this.checklist?.assurance_id +
          '&assurance_user_id=' + this.checklist?.assurance_user?.id +
          '&med_type_id=' + this.checklist?.medtype?.id +
          '&process_id=' + this.checklist?.process?.id
      } else {
        link = '/actions/' + this.item?.action?.id
      }
      return link
    }
  },
  mounted() {
    this.commentary = this.item.commentary
  },
  methods: {
    onCommentaryClick() {
      // this.openFullName = !this.openFullName
      this.showCommentaryModal = true
    },
    setCommentary() {
      if (this.commentary !== this.item.commentary) {
        this.$store.dispatch('REQUIREMENT_SET_COMMENTARY', { item: this.item, commentary: this.commentary })
      }
    },
    changeStatus() {
      clearTimeout(this.updateTimeout)
      let status_id = this.item.status_id
      for (let i = 0; i < this.allStatuses.length; i++) {
        if (this.allStatuses[i].id === this.item.status_id) {
          if (i === this.allStatuses.length - 1) {
            if (this.checklist.status_id > 1 && this.allStatuses[i].id === 4) {
              this.$store.dispatch('REQUIREMENT_SET_STATUS', {
                requirement_id: this.item.id,
                status_id: this.allStatuses[1].id,
                item: this.item
              })
              status_id = this.allStatuses[1].id
            } else {
              this.$store.dispatch('REQUIREMENT_SET_STATUS', {
                requirement_id: this.item.id,
                status_id: this.allStatuses[0].id,
                item: this.item
              })
              status_id = this.allStatuses[0].id
            }

            break
          } else {
            this.$store.dispatch('REQUIREMENT_SET_STATUS', {
              requirement_id: this.item.id,
              status_id: this.allStatuses[i + 1].id,
              item: this.item
            })
            status_id = this.allStatuses[i + 1].id
            break
          }
        }
      }
      this.updateTimeout = setTimeout(() => {
        this.$api.post('requirements/set-status', {
          id: this.item.id,
          status_id: status_id
        })
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.status-text.status-5 {
  color: #37c489;
}
.status-text {
  color: #d65959;
}
.warning {
  width: 40px;
  height: 40px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.events-column .warning:hover .warning-text {
  display: block;
}

.warning-icon.complete {
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
}
.warning-icon {
  background: #d65959;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.events-column {
  position: relative;
}

.warning-text {
  width: 200px;
  display: none;
  background-color: white;
  position: absolute;
  padding: 10px;
  z-index: 9;
  bottom: calc(100% - 25px);
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
  border-radius: 3px;
}

.full .events-text {
  white-space: normal;
}

.events-text {
  white-space: nowrap; /* Текст не переносится */
  overflow: hidden; /* Обрезаем всё за пределами блока */
  text-overflow: ellipsis;
  cursor: pointer;
  color: #1cc8ab;
  text-decoration: underline;
}

.item.finished .requirement-column {
  border-left: 1px solid #cccccc;
}

.item.gray textarea {
  background: #f2f2f2;
}

.item.gray {
  background: #f2f2f2;
}

.status-list .status-item {
  display: flex;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
}

div.commentary-column {
  overflow: hidden;
}

.requirement-text {
  margin: 0; /* Убираем внешний отступ */
  -webkit-line-clamp: 2; /* Число отображаемых строк */
  display: -webkit-box; /* Включаем флексбоксы */
  -webkit-box-orient: vertical; /* Вертикальная ориентация */
  overflow: hidden;
}

.status-list {
  position: absolute;
  top: 40px;
  padding: 5px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
  background-color: white;
  z-index: 9;
}

.status-icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.status-4-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1239_9110)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5001 11.0001L0.500075 21.0001L-0.207031 20.293L9.79297 10.293L0.500075 1.00008L1.20718 0.292969L10.5001 9.58586L19.793 0.292969L20.5001 1.00008L11.2072 10.293L21.2072 20.293L20.5001 21.0001L10.5001 11.0001Z' fill='%23666666'/%3E%3C/g%3E%3Cpath d='M10.5 19C5.52944 19 1.5 14.9706 1.5 10H-0.5C-0.5 16.0751 4.42487 21 10.5 21V19ZM19.5 10C19.5 14.9706 15.4706 19 10.5 19V21C16.5751 21 21.5 16.0751 21.5 10H19.5ZM10.5 1C15.4706 1 19.5 5.02944 19.5 10H21.5C21.5 3.92487 16.5751 -1 10.5 -1V1ZM10.5 -1C4.42487 -1 -0.5 3.92487 -0.5 10H1.5C1.5 5.02944 5.52944 1 10.5 1V-1Z' fill='%23666666'/%3E%3Cdefs%3E%3CclipPath id='clip0_1239_9110'%3E%3Cpath d='M0.5 10C0.5 4.47715 4.97715 0 10.5 0C16.0228 0 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10Z' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.status-3-icon {
  background-color: #d65959;
}

.status-2-icon {
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
}

.status-1-icon {
  background-color: #666666;
}

.item .events-column,
.item .commentary-column,
.item .status-column,
.item .events-column,
.item .id-column,
.item .requirement-column {
  border-right: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  border-top: none;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  display: flex;
}

.events-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;
  padding-left: 20px;
  flex: none;
  @media (max-width: 1376px) {
    width: 140px;
  }
}

.commentary-column {
  resize: none;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 290px;
  padding-left: 5px;
  flex: none;
  transition: all ease .2s;
}

.commentary-column:hover {
  cursor: pointer;
}

.status-column {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 175px;
  padding-left: 20px;
  flex: none;
  @media (max-width: 1376px) {
    width: 100px;
  }
}

.requirement-column.full {
}

.requirement-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  align-items: flex-start;
  overflow: hidden;
  padding-left: 20px;
}

.id-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 93px;
  padding-left: 20px;
  border-left: 1px solid #cccccc;
  flex: none;
}

.list-head {
  font-size: 16px;
  display: flex;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
  width: 100%;
}

.item-grey {
  background-color: #f2f2f2;
}

.item.full .requirement-text {
  display: block;
}

.item.full {
  height: auto;
}

.item {
  font-size: 14px;
  height: 47px;
  display: flex;
  width: 100%;
}
</style>
