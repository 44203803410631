<template>
  <NAlert v-if="!hasPermission" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
    Для получения полного доступа необходимо сменить тариф
  </NAlert>

  <div :class="{ 'disabled-by-tarif': !hasPermission }">
    <MainTabEdit v-if="editMode" @cancel-handle="toggleEdit" />
    <div v-else>
      <div v-if="hasOrganization">
        <div class="content-block pt-0">
          <div class="content-header">
            Данные об организации:
            <NextButton v-if="userIsAdmin" type="edit" color="white" text="Редактировать" @click-handler="toggleEdit" />
          </div>

          <div class="content-info">
            <organizationInformationItem :text="organization.name" header="Медицинская организация" icon="white-plus-icon" />
            <organizationInformationItem :text="organization.director" header="Руководитель" icon="white-avatar-icon" />
            <!-- <organizationInformationItem
              :text="organization.quality"
              header="Уполномоченый по качеству"
              icon="white-shield-icon"
            /> -->
          </div>
        </div>

        <!-- <div class="content-block">
          <div class="content-header">Подразделения:</div>
          <organizationInformationItem style="width: 100%; max-width: 100%; margin-bottom: 15px;" text="" header="Список подразделений" icon="white-shield-icon">
            <NSelect v-model:value="selectedSubdivisions" multiple :options="subdivisions" style="width: 100%; margin-top: 5px;" size="large" />
          </organizationInformationItem>

          <div style="padding-left: 60px;" class="d-flex">
            <NButton size="medium" @click="toggleShowSubdivisionInput">
              {{ showAddSubdivisionInput ? 'Отмена' : 'Добавить' }}
            </NButton>

            <div v-if="showAddSubdivisionInput" class="ml-3">
              <NInput v-model:value="addSubdivisionInputValue" size="large" style="width: 400px;" placeholder="Введите название подразделения" />
              <NButton class="ml-1" :disabled="addSubdivisionInputValue.length === 0" @click="addSubdivision">
                Сохранить
              </NButton>
            </div>
          </div>
        </div> -->

        <div class="content-block">
          <div class="content-header">Сведения о юридическом лице:</div>
          <div class="content-info">
            <organizationInformationItem :text="organization.inn" :header="'ИНН'" :icon="'white-number-icon'" />
            <organizationInformationItem :text="organization.kpp" :header="'КПП'" :icon="'white-number-icon'" />
            <organizationInformationItem :text="organization.ogrn" :header="'ОГРН'" :icon="'white-number-icon'" />
            <organizationInformationItem
              :text="organization.address"
              :header="'Юридический адрес'"
              :icon="'white-point-icon'"
            />
          </div>
        </div>
        <div class="content-block">
          <div class="content-header">Контактная информация:</div>
          <div class="content-info">
            <organizationInformationItem :text="organization.phone" header="Телефон" icon="white-phone-icon" />
            <organizationInformationItem :text="organization.fax" header="Факс" icon="white-fax-icon" />
            <organizationInformationItem :text="organization.email" header="Электронная почта" icon="white-email-icon" />
            <organizationInformationItem :text="organization.site" header="Сайт" icon="white-site-icon" />
            <organizationInformationItem :text="organization.mail_address" header="Почтовый адрес" icon="white-point-icon" />
          </div>
        </div>
        <!-- <div class="content-block">
          <div class="content-header">Вид медицинской помощи:</div>
          <div class="content-info">
            <div class="content-list">
              <div v-for="(item, key) in selectedMedHelps" :key="key" class="med-item">{{ item.text }}</div>
            </div>
          </div>
        </div>
        <div class="content-block border-0 pb-0">
          <div class="content-header">Вид медицинской деятельности:</div>
          <div class="content-info">
            <div class="content-list">
              <div v-for="(item, key) in selectMedActions" :key="key" class="med-item">{{ item.text }}</div>
            </div>
          </div>
        </div> -->
      </div>
      <NButton v-else class="mt-5" type="primary" @click="editMode = true"> Создать организацию </NButton>
    </div>
  </div>
</template>

<script>
import { NButton, NAlert, NSelect, NInput, NIcon, useMessage } from 'naive-ui'
import SearchIcon from '@vicons/tabler/Search'

import MainTabEdit from '@/components/pages/organization/MainTabEdit'
import organizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import { useOrganizationStore } from '@/store/organization'

export default {
  name: 'MainTab',
  components: { organizationInformationItem, NextButton, MainTabEdit, NButton, NAlert, NSelect, NInput, NIcon, SearchIcon },
  props: {
    hasOrganization: {
      type: Boolean,
      required: true,
    },
    hasPermission: {
      type: Boolean
    }
  },
  setup() {
    const organizationStore = useOrganizationStore()
    const organization = organizationStore.organization
    const message = useMessage()
    const userIsAdmin = [1, 2].includes(JSON.parse(localStorage.user).role_id)

    return {
      organization,
      message,
      userIsAdmin
    }
  },
  data() {
    return {
      selectedMedHelps: [],
      selectMedActions: [],
      editMode: false,
      subdivisions: [
      {
          label: 'Тест 1',
          value: '1'
        },
        {
          label: 'Тест 2',
          value: '2'
        },
      ],
      selectedSubdivisions: [],
      showAddSubdivisionInput: false,
      addSubdivisionInputValue: '',
    }
  },
  mounted() {
    this.getMedHelps()
    this.getMedActions()
  },
  methods: {
    async getMedActions() {
      let result = await this.$api.get(
        'organization/get-organization-medactions?id=' + this.$store.getters['currentOrganization'].id
      )
      this.selectMedActions = result.data.data
    },
    async getMedHelps() {
      let result = await this.$api.get(
        'organization/get-organization-medhelps?id=' + this.$store.getters['currentOrganization'].id
      )
      this.selectedMedHelps = result.data.data
    },
    toggleEdit() {
      if (this.editMode) {
        location.reload()
      }
      this.editMode = !this.editMode
    },
    addSubdivision() {
      if (this.addSubdivisionInputValue.length) {
        this.subdivisions.push({
          label: this.addSubdivisionInputValue,
          value: new Date(),
        })

        this.message.success('Подразделение добавлено')
      }
      
    },
    toggleShowSubdivisionInput() {
      this.addSubdivisionInputValue = ''
      this.showAddSubdivisionInput = !this.showAddSubdivisionInput
    }
  }
}
</script>

<style scoped>
.content-list {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.content-info .information-item {
  padding-bottom: 30px;
}

.med-item {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #f2f2f2;
  box-shadow: 1px 1px 0px #cccccc;
  border-radius: 3px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.content-block {
  border-bottom: 1px solid #cccccc;
  padding: 24px 0;
}

.information-icon {
  flex: none;
}

.content-header {
  display: flex;
  justify-content: space-between;
}

.content-info {
  display: flex;
  flex-wrap: wrap;
}
</style>
