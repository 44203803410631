import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useMessage, useNotification } from 'naive-ui'
import api from '@/api'

export const useIncidentsStore = defineStore('incidents', () => {
  const endpoint = 'inevent/'

  const message = useMessage()
  const notify = useNotification()

  const isLoading = ref(true)
  const isSaving = ref(false)
  const isFetching = ref(false)
  const isDeleting = ref(false)
  const isError = ref(false)
  const error = ref(null)
  const list = ref([])
  const item = ref(null)

  const fetch = async ({ risk_id, date_start = '', date_end = '' }) => {
    try {
      // ${date_start ? `date_start=${date_start}&` : ''}${date_end ? `date_end=${date_end}&` : ''}
      const url = `${endpoint}?${risk_id ? `risk_id=${risk_id}&` : ''}`
      const res = await api.get(url)

      if (res.status === 200) {
        list.value = res.data
      }
    } catch (error) {
      notify.error({
        title: 'Не удалось получить список',
        duration: 2500
      })
    }
  }

  const fetchOne = async (id, showLoading = true) => {
    try {
      isLoading.value = showLoading
      isFetching.value = true
      isError.value = false
      error.value = null

      const res = await api.get(`${endpoint}get`, {
        params: {
          inevent_id: id
        }
      })
      // const statusRes = await getStatus(id)

      if (res.data.id) {
        item.value = res.data
        return res
      }

      throw new Error(res.data.message)
    } catch (e) {
      isError.value = true
      error.value = e?.response?.data?.message || e.message
    } finally {
      isLoading.value = false
      isFetching.value = false
    }
  }

  const create = async (payload, showMessage = true) => {
    try {
      isSaving.value = true

      const res = await api.post(endpoint, payload)

      if (res.data.id) {
        message.success('Нежелательное событие зарегистрировано')
        return res.data
      }

      if (res.data.errors) {
        notify.error({
          title: 'Произошла ошибка!',
          content: JSON.stringify(res.data.errors),
          duration: 3000
        })
      }
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось создать',
        duration: 3000
      })
      console.log(e)
    } finally {
      isSaving.value = false
    }
  }

  const update = async (id, payload) => {
    try {
      isSaving.value = true

      const res = await api.put(`${endpoint}edit?inevent_id=${id}`, payload)

      await fetchOne(id, false)
      message.success('Статус обновлен')

      if (res.status === 200) {
        message.success('Обновлено')
        return res
      }
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось обновить',
        duration: 2500
      })
    } finally {
      isSaving.value = false
    }
  }

  const deleteById = async (id) => {
    try {
      isDeleting.value = true

      // const res = await api.post(endpoint + 'delete', { id })
      const res = await api.delete(`${endpoint}delete?inevent_id=${id}`)

      message.success('Удалено')
      list.value = list.value.filter((item) => item.id !== id)
      item.value = null

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось удалить',
        duration: 2500
      })
    } finally {
      isDeleting.value = false
    }
  }

  const getStatus = async (id) => {
    try {
      const res = await api.get('/event-state-machine/status', { params: { id } })
      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось получить статус мероприятия',
        duration: 2500
      })
    }
  }

  const updateStatus = async (id, status_id) => {
    try {
      const res = await api.put(`${endpoint}edit?inevent_id=${id}`, {
        status: status_id
      })

      await fetchOne(id, false)
      message.success('Статус обновлен')

      return res
    } catch (e) {
      notify.error({
        title: 'Произошла ошибка!',
        content: 'Не удалось изменить статус',
        duration: 2500
      })
    }
  }

  return {
    isLoading,
    isFetching,
    isDeleting,
    isError,
    error,
    list,
    item,
    fetch,
    fetchOne,
    create,
    update,
    deleteById,
    getStatus,
    updateStatus
  }
})
