<template>
  <NAlert v-if="!hasPermission" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
    Для получения полного доступа необходимо сменить тариф
  </NAlert>

  <div class="content-block" :class="{ 'disabled-by-tarif': !hasPermission }">
    <div v-if="$store.getters['allMedTYpes'].length > 0" class="content-info">
      <div v-for="(item, key) in $store.getters['allMedTYpes']" :key="key" class="content-info__item">
        <OrgAccordion
          v-if="!pause"
          :selected-items="$store.getters['organizationProcesses']"
          :header="item.text"
          :items="item.processes"
          @item-click="selectProcess"
        />
      </div>
      <SpinLoader v-if="pause" class="py-5" size="2xl" />
    </div>
  </div>
</template>

<script>
import OrgAccordion from '@/components/widgets/custom-components/accordion/OrgAccordion'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import { NAlert } from 'naive-ui'

export default {
  name: 'ProcessInformation',
  components: {
    OrgAccordion,
    SpinLoader,
    NAlert
  },
  props: {
    hasPermission: {
      type: Boolean,
    }
  },
  data() {
    return {
      pause: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async selectProcess(item) {
      if (item.active) {
        await this.$store.dispatch('PROCESS_CREATE_ORGANIZATION_PROCESS', {
          process_id: item.id,
          organization_id: this.$store.getters['currentOrganization'].id
        })
        item.active = true
        item.pause = false
      } else {
        await this.$store.dispatch('PROCESS_REMOVE_ORGANIZATION_PROCESS', {
          process_id: item.id,
          organization_id: this.$store.getters['currentOrganization'].id
        })
        item.active = false
        item.pause = false
      }
    },
    async init() {
      this.$store.dispatch('PROCESS_UPDATE_ALL_MEDTYPES')
      this.pause = true
      await this.$store.dispatch('ORGANIZATION_UPDATE_ORGANIZATION_PROCESSES')
      this.pause = false
      /*
      this.$store.dispatch('ORGANIZATION_UPDATE_ORGANIZATION_PROCESSES', this.$store.getters['currentOrganization'].id)*/
    }
  }
}
</script>

<style scoped>
.content-info__item {
  padding-bottom: 10px;
}

.content-header {
  font-size: 23px;
  line-height: 31px;
  padding-bottom: 23px;
}
</style>
