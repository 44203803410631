import { toPercent, makeDate } from '@/helper'

export default [
  {
    key: 'number',
    title: '№',
    sortable: true,
    sortOrder: 'DESC',
    width: '80px'
  },
  {
    key: 'text',
    title: 'Раздел',
    sortable: true,
    width: '40%'
  },
  {
    key: 'type',
    title: 'Направление',
    sortable: true,
    render: (row) => row.medtype.text
  },
  {
    key: 'requirement',
    title: 'Требования',
    sortable: true,
    render: (row) => row.requirements_count.all
  },
  {
    key: 'checked',
    title: 'Проверено',
    sortable: true,
    render: (row) => row.requirements_count.checked
  },
  {
    key: 'passed',
    title: 'Соответствия',
    sortable: true,
    render: (row) => toPercent(row.requirements_count.all, row.requirements_count.passed) + '%'
  },
  {
    key: 'date',
    title: 'Дата',
    sortable: true,
    render: (row) => makeDate(row?.checklist?.finish_date)
  },
  {
    key: 'checklist',
    title: 'Отчёт',
    sortable: true,
  }
]
