<template>
  <div class="list">
    <div v-if="items.length > 0" class="list-head">
      <div class="name-column sort-item" @click="changeSort('name')">
        Название документа
        <span class="sort-icon" :class="[{ active: sort.type === 'name' }, sort.direction]" />
      </div>
      <div class="file-column sort-item" @click="changeSort('file')">
        Файл
        <span class="sort-icon" :class="[{ active: sort.type === 'file' }, sort.direction]" />
      </div>
      <div class="category-column sort-item" @click="changeSort('category')">
        Категория
        <span class="sort-icon" :class="[{ active: sort.type === 'category' }, sort.direction]" />
      </div>
      <div class="date-column sort-item" @click="changeSort('date')">
        Дата загрузки
        <span class="sort-icon" :class="[{ active: sort.type === 'date' }, sort.direction]" />
      </div>
      <div class="events-column">Действия</div>
    </div>
    <div v-if="items.length > 0" class="list-content">
      <template v-for="(item, key) in list" :key="key">
        <documentItem
          :gray="key % 2 !== 0"
          :document-id="documentId"
          :type="type"
          :item="item"
          @delete-document="deleteDocument"
        />
      </template>
    </div>
    <div v-if="items.length === 0" class="no-content">Здесь пока нет документов.</div>
  </div>
</template>

<script>
import documentItem from '@/components/widgets/simple-components/items/documentItem'
export default {
  name: 'OrganizationDocumentsList',
  components: { documentItem },
  props: {
    sort: {
      type: Object,
      default() {
        return {
          type: 'date',
          direction: 'desc'
        }
      }
    },
    documentId: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    list() {
      this.items.length
      return this.items
    }
  },
  mounted() {},
  methods: {
    changeSort(type) {
      let sort = {}
      if (type === this.sort.type) {
        sort.type = this.sort.type
        sort.direction = this.sort.direction === 'desc' ? 'asc' : 'desc'
      } else {
        sort.type = type
        sort.direction = 'desc'
      }
      this.$emit('changeSort', sort)
    },
    deleteDocument() {
      this.$emit('deleteDocument')
    }
  }
}
</script>

<style scoped>
.sort-item {
  cursor: pointer;
}
.sort-icon.desc {
  transform: rotate(180deg);
  background-position-y: -3px;
}
.sort-icon.active {
  opacity: 1;
}
.sort-icon {
  opacity: 0;
  display: inline-block;
  background-size: contain;
  background-position-y: 4px;
  margin-left: 5px;
  width: 17px;
  height: 17px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32px' height='32px' viewBox='0 0 32 32'%3E%3Cpath d='M 16 8.59375 L 15.28125 9.28125 L 5.28125 19.28125 L 3.59375 21 L 28.40625 21 L 26.71875 19.28125 L 16.71875 9.28125 Z M 16 11.4375 L 23.5625 19 L 8.4375 19 Z' fill='white'/%3E%3C/svg%3E");
}
.list-head {
  font-size: 16px;
  display: flex;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
  width: 100%;
}
.events-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 160px;
  padding-left: 20px;
  flex: none;
}
.category-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 175px;
  padding-left: 20px;
  flex: none;
}

.file-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 250px;
  flex: none;
  padding-left: 20px;
}
.name-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  padding-left: 20px;
  border-left: 1px solid #cccccc;
}
.date-column {
  width: 200px;
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  flex: none;
}
</style>
