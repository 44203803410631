<script setup>
import { h, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { NDataTable } from 'naive-ui'
import { Check } from '@vicons/tabler'
import ModalWindow from '@/components/widgets/custom-components/modalWindow/modalWindow'
import Profile from '@/components/widgets/custom-components/modalWindow/modalComponents/profile'
import TableActions from '@/components/common/TableActions'
import { useDocumentsStore } from '@/store/documents'
import { useUsersStore } from '@/store/users'
import { renderIcon } from '@/utils/icon'

const store = useStore()
const documentsStore = useDocumentsStore()
const usersStore = useUsersStore()

const isOpenEdit = ref(false)
const editingUser = ref(null)
const checkIcon = renderIcon(Check, {
  wrapperProps: { class: 'bg-green-500', borderRadius: 8, size: 16 },
  iconProps: { size: 10, strokeWidth: '2px', style: 'margin-bottom: 1px' }
})

const columns = [
  { 
    key: 'name', 
    title: 'ФИО пользователя', 
    width: 300, 
    render: (row) => h('div', { style: { fontWeight: row.departments.length ? 400 : 700, textDecoration: row.departments.length ? 'none' : 'underline' } }, { default: () => row.name })
  },
  { key: 'post', title: 'Должность' },
  { key: 'phone', title: 'Телефон', width: 150, },
  { key: 'email', title: 'Email', width: 250, },
  {
    key: 'signature',
    title: 'ЭП',
    align: 'center',
    width: 60,
    render: (row) => (row.signature ? checkIcon : '')
  },
  {
    key: 'vkk',
    title: 'Комиссия ВКК и БМД',
    align: 'center',
    maxWidth: 160,
    // render: (row) => (row.vkk ? renderIcon(Check) : '')
    render: (row) => (row.vkk ? checkIcon : '')
  },
  {
    key: 'med_commission',
    title: 'Врачебная комиссия',
    align: 'center',
    maxWidth: 180,
    render: (row) => (row.med_commission ? checkIcon : '')
  },
  {
    key: 'actions',
    title: '',
    width: 80,
    align: 'center',
    render: (row) => h(TableActions, { row, options: getActions(row) })
  }
]

onMounted(() => {
  usersStore.getResponsibleUsers()
})

function getActions(row) {
  const actions = {}

  if (store.getters.isSuperAdmin && store.getters.currentUser.id !== row.id) {
    actions.delete = {
      confirm: {
        type: 'error',
        content: 'Вы действительно хотите удалить этого пользователя?'
      },
      selectHandler: async () => {
        if (row?.instruction?.id) {
          await documentsStore.deleteById(row.instruction.id, false)
        }

        await usersStore.deleteById(row.id)
      }
    }
  }

  if (store.getters.isSuperAdmin || store.getters.currentUser.id === row.id) {
    actions.edit = {
      selectHandler: async () => {
        editingUser.value = row
        isOpenEdit.value = true
      }
    }
  }

  return actions
}

// TODO: Перейти на pinia
async function changeUser(user) {
  if (user.id === store.getters.currentUser.id) {
    store.dispatch('USER_GET')
  }
  usersStore.getResponsibleUsers()
  isOpenEdit.value = false
  editingUser.value = null
}
</script>

<template>
  <NDataTable :columns="columns" :data="usersStore.items.sort((a, b) => a.name.localeCompare(b.name))" :loading="usersStore.isLoading" />
  <ModalWindow v-if="isOpenEdit" header="Редактирование пользователя" @closeHandler="isOpenEdit = false">
    <Profile :item="editingUser" edit @changeHandler="changeUser" />
  </ModalWindow>
</template>
