<template>
  <div class="login">
    <form class="login-form">
      <div class="login-form__header">
        <div class="background__lines" />
        <div class="login-form__header_text">Регистрация в системе</div>
      </div>
      <div class="message">{{ errorMessage }}</div>
      <div class="input-block">
        <div class="input-row">
          <inputWithHeader
            :class="[{ success: name.length > 0 }, { full_width: type === 'invite' }]"
            :autocomplete="false"
            class="custom-input"
            placeholder="Введите ФИО"
            required
            header="Ваше ФИО:"
            @enter-handler="registration"
            @input-handler="nameInput"
          />
          <inputWithHeader
            v-if="type === 'register'"
            :message="INNMessage"
            :class="[{ warning: innWarning }, { success: currentOrganization.inn }]"
            :autocomplete="false"
            type="number"
            class="custom-input"
            placeholder="Введите ИНН организации"
            required
            header="ИНН организации: "
            @enter-handler="registration"
            @input-handler="INNInput"
          />
        </div>
        <div class="input-row">
          <inputWithHeader
            :class="{ success: checkEmail, warning: email.length > 0 && !checkEmail }"
            :autocomplete="false"
            class="custom-input"
            placeholder="Введите ваш E-mail"
            required
            name="login"
            header="Ваш E-mail: "
            type="text"
            @enter-handler="login"
            @input-handler="emailInput"
          />
          <inputWithHeader
            :class="{ success: phone.length > 6, warning: phone.length > 0 && phone.length < 6 }"
            :autocomplete="false"
            class="custom-input"
            placeholder="Введите ваш телефон"
            required
            header="Ваш телефон: "
            type="tel"
            @enter-handler="login"
            @input-handler="phoneInput"
          />
        </div>
        <div class="input-row">
          <inputWithHeader
            :class="{ success: password.length > 5, warning: password.length > 0 && password.length < 6 }"
            :autocomplete="false"
            class="custom-input"
            placeholder="Не короче 6 символов"
            required
            name="password"
            header="Ваш пароль: "
            type="password"
            @enter-handler="login"
            @input-handler="passwordInput"
          />
          <inputWithHeader
            :class="{ success: password === confirmPassword && password.length > 0 }"
            :title="checkPasswordConfirm"
            :message="confirmPasswordMessage"
            :autocomplete="false"
            class="custom-input"
            placeholder="Подтвердите пароль"
            required
            header="Подтверждение пароля: "
            type="password"
            @enter-handler="login"
            @input-handler="confirmPasswordInput"
          />
        </div>
      </div>
      <div class="checkbox-container flex-row d-flex justify-content-between">
        <checkbox
          :start-active="true"
          label="Я даю своё согласие на обработку. <a style='color: #1CC8AB' href='http://vkkinform.ru/assets/docs/user-agreement-personal.pdf' target='_blank'>персональных данных.</a>"
          @change-handler="changePersonal"
        />
        <checkbox
          :start-active="true"
          label="Я принимаю условия <a style='color: #1CC8AB' href='http://vkkinform.ru/user-agreement.pdf' target='_blank'>пользовательского соглашения.</a> <span style='color: #1CC8AB'> *</span>"
          @change-handler="changeAgreement"
        />
      </div>
      <div class="checkbox-container flex-row d-flex justify-content-between">
        <checkbox
          :start-active="true"
          label="Настоящим подтверждаю, что ознакомлен(а) с <a style='color: #1CC8AB' href='http://vkkinform.ru/assets/docs/policy.pdf' target='_blank'>Политикой конфиденциальности</a> <span style='color: #1CC8AB'> *</span>"
          @change-handler="changePolicy"
        />
      </div>
    </form>
    <div class="login-buttons">
      <nextButton
        :title="checkForm"
        :class="{ inactive: checkForm !== '' }"
        :text="'ЗАРЕГИСТРИРОВАТЬСЯ'"
        @click-handler="registration"
      />
      <nextButton color="white" text="ОТМЕНИТЬ" @click-handler="login" />
    </div>
  </div>
</template>

<script>
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import whiteLogo from '@/components/widgets/simple-components/logo/whiteLogo'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import checkbox from '@/components/widgets/simple-components/inputs/checkbox'

export default {
  name: 'RegistrationForm',
  components: {
    nextButton,
    whiteLogo,
    inputWithHeader,
    checkbox
  },
  props: {
    type: {
      type: String,
      default() {
        return 'invite'
      }
    }
  },
  data() {
    return {
      currentOrganization: '',
      email: '',
      name: '',
      phone: '',
      inn: '',
      password: '',
      confirmPassword: '',
      errorMessage: '',
      personalAgree: true,
      policyAgree: true,
      agreementAgree: true
    }
  },
  computed: {
    checkEmail() {
      if (this.email.length < 5) {
        return false
      }
      if (this.email === '') {
        return false
      } else if (
        this.email.indexOf('@') === -1 ||
        this.email.indexOf('@') === 0 ||
        this.email.indexOf('@') > this.email.length - 2
      ) {
        return false
      }
      return true
    },
    confirmPasswordMessage() {
      if (this.checkPasswordConfirm === '') {
        return false
      } else {
        return { text: 'Пароли не совпадают', type: 'error' }
      }
    },
    INNMessage() {
      if (this.innWarning) {
        return { text: 'ИНН должен содержать 10 символов и должен принадлежать реальной компании', type: 'error' }
      } else {
        return false
      }
    },
    innWarning() {
      if (this.inn.length > 10) {
        return true
      }
      if (this.currentOrganization === false) {
        return true
      } else {
        return false
      }
    },
    checkForm() {
      let text = ''
      if (this.name === '') {
        text += 'Введите имя. '
      }
      if (this.email === '') {
        text += 'Введите почту. '
      } else if (
        this.email.indexOf('@') === -1 ||
        this.email.indexOf('@') === 0 ||
        this.email.indexOf('@') > this.email.length - 2
      ) {
        text += 'Некорректный адрес почты. '
      }
      if (this.password === '') {
        text += 'Введите пароль. '
      } else if (this.password.length < 6) {
        text += 'Пароль должен содеражть не меньше 6 символов. '
      }
      if (this.password !== this.confirmPassword) {
        text += 'Пароли не совпадают. '
      }
      if (!this.personalAgree) {
        text += 'Подтвердите согласие на обработку персональных данных. '
      }
      if (!this.agreementAgree) {
        text += 'Подтвердите согласие с пользовательским соглашением. '
      }
      if (!this.policyAgree) {
        text += 'Подтвердите согласие с политикой конфиденциальности. '
      }
      if (this.type === 'register') {
        if (!this.currentOrganization.inn) {
          text += 'Введите верный ИНН организации. '
        }
      }
      return text
    },
    checkPasswordConfirm() {
      if (this.password !== this.confirmPassword) {
        return 'Пароли не совпадают'
      } else {
        return ''
      }
    }
  },
  mounted() {},
  methods: {
    phoneInput(val) {
      this.phone = val
    },
    INNInput(val) {
      this.currentOrganization = ''
      this.inn = val
      if (this.inn.length === 10) {
        this.getOrganization()
          .then((response) => response.text())
          .then((res) => {
            res = JSON.parse(res)
            if (res.suggestions.length > 0) {
              this.currentOrganization = res.suggestions[0].data
            } else {
              this.currentOrganization = false
            }
          })
      }
    },
    getOrganization() {
      var url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party'
      var token = 'ee9152eb17507fefb897bd19ae22398df9dfc8a4'
      var query = this.inn

      var options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token ' + token
        },
        body: JSON.stringify({ query: query })
      }
      return fetch(url, options)
      /* return fetch(url, options)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log("error", error));*/
    },
    changePersonal(val) {
      this.personalAgree = val
    },
    changeAgreement(val) {
      this.agreementAgree = val
    },
    changePolicy(val) {
      this.policyAgree = val
    },
    async registration() {
      if (this.checkForm !== '') {
        this.$store.dispatch('ALERT_CREATE', { text: this.checkForm, type: 'warning' })
        return
      }
      let user = {
        name: this.name,
        email: this.email,
        password: this.password,
        host: window.location.host,
        phone: this.phone
      }
      let action = 'auth/invite'
      if (this.type === 'register') {
        action = 'auth/register'
        user.organization = {
          name: this.currentOrganization.name.short_with_opf,
          inn: this.currentOrganization.inn,
          kpp: this.currentOrganization.kpp,
          ogrn: this.currentOrganization.ogrn,
          address: this.currentOrganization.address.value,
          phone: this.currentOrganization.phones?.phone,
          fax: this.currentOrganization.phones?.fax,
          mail_address: this.currentOrganization.address.unrestricted_value,
          email: this.currentOrganization.emails,
          site: '',
          director: this.currentOrganization.management.name
        }
      } else {
        user.invite_link = this.$router.currentRoute.value.params.link
      }
      this.$store.dispatch(action, user).then((res) => {
        if (res.ok == 1) {
          this.$router.push('/registered')
        } else if (res.ok === 0) {
          this.errorMessage = res.message
        }
      })
    },
    confirmPasswordInput(val) {
      this.confirmPassword = val
    },
    passwordInput(val) {
      this.password = val
    },
    nameInput(val) {
      this.name = val
    },
    emailInput(val) {
      this.email = val
    },
    login() {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.full_width.custom-input {
  width: 100%;
}
.input__header {
  font-size: 14px;
  line-height: 19px;
  color: #666666;
}

.checkbox-container {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
}

.login-form__header_text {
  position: relative;
  font-size: 23px;
}

.login-buttons {
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
}

.login-form__header {
  position: relative;
  padding-left: 40px;
  padding-top: 35px;
  padding-bottom: 20px;
}

.login {
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
  border-radius: 3px;
  padding-bottom: 55px;
}

.input-block {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.login-form {
  width: 646px;
}

.message {
  padding: 20px 35px;
}

.input-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.custom-input {
  padding-bottom: 21px;
  width: 48%;
}

.background__lines {
  background: url('@/assets/images/background_lines.png');
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  opacity: 0.35;
  background-position: top;
  left: 0;
  z-index: 0;
  top: 0;
}
</style>
