<script setup>
import { onMounted, ref, computed, watch } from 'vue'
import { NButton, NH1, useDialog } from 'naive-ui'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useEventsStore } from '@/store/events'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import ActionForm from '@/components/pages/Actions/actionComponents/ActionForm'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'

const route = useRoute()
const router = useRouter()
const dialog = useDialog()

const eventsStore = useEventsStore()
const { isSaving, isLoading, isDeleting, isError, error, item: event } = storeToRefs(eventsStore)

const formRef = ref(null)
const checklistNumber = ref(null)
const eventId = computed(() => route.params.id)

const isCreatedFromChecklist = computed(() => !!event.value?.checklist)

watch(isCreatedFromChecklist, (v) => {
  // Сохранение номера чеклиста для редиректа после удаления мероприятия
  if (v) checklistNumber.value = event.value?.checklist.number
})

onMounted(async () => {
  await eventsStore.fetchOne(route.params.id)

  if (event?.action?.status_id === 5) {
    await router.push('/actions/' + route.params.id)
  }
})

function cancel() {
  router.push('/actions/' + eventId.value)
}

async function save() {
  formRef.value?.validate((errors, values) => {
    if (errors) return

    eventsStore.update(values).then(() => {
      router.push('/actions/' + eventId.value)
    })
  })
}

function deleteHandler() {
  const d = dialog.error({
    title: 'Подтвердите действие',
    content: 'Вы действительно хотите удалить этот объект?',
    positiveText: 'Удалить',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return eventsStore.deleteById(eventId.value).then(() => {
        if (checklistNumber.value) {
          router.push(`/checklist/${checklistNumber.value}`)
        } else {
          router.push('/actions')
        }
      })
    }
  })
}
</script>

<template>
  <div class="max-w-6xl mx-auto">
    <div class="page-header">
      <div class="page-header__left">
        <NH1 class="mb-0">Редактирование мероприятия #{{ route.params.id }}</NH1>
      </div>
      <div class="page-header__right">
        <div v-if="event" class="page-header__controls">
          <NButton type="primary" :loading="isSaving" :disabled="isDeleting" @click="save">Сохранить</NButton>
          <NButton :disabled="isSaving || isDeleting" strong secondary @click="cancel">Отмена</NButton>
        </div>
      </div>
    </div>
    <div class="content">
      <NextAlert v-if="isError" class="mb-4" type="error" :text="error" />
      <SpinLoader v-if="isLoading" class="py-5" size="2xl" />
      <div v-else class="max-w-200">
        <div v-if="isCreatedFromChecklist" class="mb-4">
          <div class="mb-3">
            <div class="font-weight-bold">Выявленное несоответствие</div>
            <p class="text-secondary">{{ event.checklist_requirement?.commentary || '—' }}</p>
          </div>
          <div class="d-flex align-items-center mb-3">
            <div class="font-weight-bold mr-2">Отчёт:</div>
            <RouterLink v-if="event?.checklist?.number" :to="`/checklist/${event?.checklist?.number}`">
              {{ event?.checklist?.number }}
            </RouterLink>
            <div v-else class="text-secondary">—</div>
          </div>
        </div>
        <ActionForm ref="formRef" :data="event" is-edit />
      </div>
    </div>
    <NButton
      v-if="event?.action?.status_id === 1"
      class="mt-3"
      type="error"
      :loading="isDeleting"
      :disabled="isSaving"
      @click="deleteHandler"
    >
      Удалить
    </NButton>
  </div>
</template>

<style scoped>
.content {
  padding: 40px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.125);
}

@media (max-width: 800px) {
  .page-header {
    flex-direction: column;
  }

  .page-header__left {
    margin-bottom: 20px;
  }

  .content {
    padding: 30px 15px;
  }
}
</style>
