<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useDialog, NAlert } from 'naive-ui'
import { isEmpty } from 'lodash'
import ViewTabMain from '@/components/pages/Risks/ViewTabMain'
import ViewTabActions from '@/components/pages/Risks/ViewTabActions'
import Documents from '@/components/pages/Risks/ViewTabDocuments'
import ViewTabIncidents from '@/components/pages/Risks/ViewTabIncidents'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import SelectProcessModal from '@/components/pages/Actions/actionComponents/selectProcessModal'

import { useRisksStore } from '@/store/risks'
import useTarifExtension from '@/composables/useTarifExtension'

const { hasPermission } = useTarifExtension()
const route = useRoute()
const router = useRouter()

const dialog = useDialog()


const risksStore = useRisksStore()
const { item: risk } = storeToRefs(risksStore)

const selectProcessModalRef = ref(null)

const currentTab = ref('main')

const riskId = computed(() => route.params.id)
// const allowEdit = computed(() => ![5, 6].includes(event.value?.action?.status_id))
// const statusBtns = computed(() => event.value?.status?.buttons)
// const topStatusBtns = computed(() =>
//   !isEmpty(statusBtns.value)
//     ? Object.keys(statusBtns.value)
//         .filter((key) => key !== 'cancel_btn')
//         .map((key) => statusBtns.value[key])
//     : []
// )

onMounted(() => {
  risksStore.fetchOne(riskId.value)

  if (route?.query?.tab === 'incidents') {
    currentTab.value = 'incidents'
  }
})

function changeTab(tab = 'main') {
  currentTab.value = tab
}

function deleteHandler() {
  const d = dialog.error({
    title: 'Подтвердите действие',
    content: 'Вы действительно хотите удалить этот объект?',
    positiveText: 'Удалить',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return risksStore.deleteById(riskId.value).then(res => {
        if (res.status === 200) {
          router.push('/risks')
        }
      })
    }
  })
}

// function changeStatusHandler(btn) {
//   if (
//     !event.value.checklist &&
//     (!event.value?.action?.process_id || !event.value?.action?.action_category_id) &&
//     event.value.action.status_id === 4 &&
//     btn.name !== 'Доработать' &&
//     btn.name !== 'Отмена'
//   ) {
//     selectProcessModalRef.value?.openModal(event.value.action, ({ med_type_id, process_id, action_category_id }) =>
//       eventsStore
//         .update(
//           {
//             ...event.value.action,
//             med_type_id,
//             process_id,
//             action_category_id
//           },
//           false
//         )
//         .then(() => {
//           eventsStore.updateStatus(riskId.value, btn.status_id)
//         })
//     )
//   } else {
//     const d = dialog.warning({
//       title: 'Подтвердите действие',
//       content: 'Вы действительно хотите изменить статус ?',
//       positiveText: 'Изменить статус',
//       negativeText: 'Отмена',
//       onPositiveClick: async () => {
//         d.loading = true
//         try {
//           await eventsStore.updateStatus(riskId.value, btn.status_id)
//         } catch (e) {
//           console.log(e)
//         }
//       }
//     })
//   }
// }
</script>

<template>
  <SpinLoader v-if="isLoading && !isError" class="py-5" size="2xl" />
  <template v-else-if="!isLoading && !isError && risk">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__text">
          <div class="page-header__heading">Паспорт риска #{{ riskId }}</div>
          <!-- <div class="page-header__second-heading">{{ event?.medtype?.text }}</div> -->
        </div>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <!-- <template v-if="statusBtns">
            <NextButton
              v-for="(btn, key) in topStatusBtns"
              :key="key"
              :text="btn.name"
              type=""
              @click="changeStatusHandler(btn)"
            />
          </template> -->
          <!-- <NextButton :to="`/risks/create`" type="next" text="Создать мероприятие" /> -->
          <NextButton :to="`/risks/${riskId}/edit`" type="edit" text="Редактировать" color="white" />
          <NextButton
            type="close"
            text="Удалить"
            color="red"
            @click="deleteHandler"
          />
          <NextButton
            text="Регистрация НС"
            type="add"
            :to="`/incidents/create?risk_id=${riskId}`"
          />
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="tab pointer" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">
        Общая информация
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'actions' }" @click="changeTab('actions')">
        Мероприятия
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'documents' }" @click="changeTab('documents')">
        Документы
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'incidents' }" @click="changeTab('incidents')">
        Нежелательные события
      </div>
    </div>
    <div class="tab-content">
      <ViewTabMain v-if="currentTab === 'main'" :data="risk" />
      <ViewTabActions v-if="currentTab === 'actions'" :data="risk" />

      <div v-if="currentTab === 'documents'">
        <NAlert v-if="!hasPermission(18)" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
          Для получения полного доступа необходимо сменить тариф
        </NAlert>
        <div :class="{ 'disabled-by-tarif': !hasPermission(18) }">
          <Documents type="risk-registry" :document-id="risk?.id" />
        </div>
      </div>

      <ViewTabIncidents v-if="currentTab === 'incidents'" />
    </div>
    <!-- <NextButton
      v-if="statusBtns?.cancel_btn"
      class="mt-4"
      color="red"
      type=""
      :text="statusBtns?.cancel_btn?.name"
      @click="changeStatusHandler(statusBtns?.cancel_btn)"
    /> -->
  </template>
  <NextAlert v-if="isError && !isLoading" type="error" :text="isError" />
  <SelectProcessModal ref="selectProcessModalRef" />
</template>
