<template>
  <div class="list" :class="type">
    <div class="list-head">
      <div class="id-column">Номер</div>
      <div class="requirement-column">Требование</div>
      <div class="status-column">Статус</div>
      <div class="commentary-column">
        <span v-if="type === 'edit'">Комментарий</span>
        <span v-else>Выявленные несоответствия</span>
      </div>
      <div class="events-column">Мероприятия</div>
    </div>
    <div class="list-content">
      <requirementListItem
        v-for="(item, key) in list"
        :key="item.id"
        :class="{ gray: key % 2 !== 0 }"
        :type="type"
        :checklist="checklist"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import requirementListItem from '@/components/widgets/simple-components/items/requirementListItem'
export default {
  name: 'RequirementList',
  components: { requirementListItem },
  props: {
    readOnly: {
      type: Boolean,
      default() {
        return false
      }
    },
    type: {
      type: String,
      default() {
        return 'edit'
      }
    },
    checklist: {
      default() {
        return false
      }
    },
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    list() {
      let items = this.items.slice('')
      items.sort((a, b) => {
        if (a.requirement.number < b.requirement.number) {
          return -1
        }
        if (a.requirement.number > b.requirement.number) {
          return 1
        }
        return 0
      })
      return items
    }
  }
}
</script>

<style scoped lang="scss">
.events-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;
  padding-left: 20px;
  flex: none;
  @media (max-width: 1376px) {
    width: 140px;
  }
}
.commentary-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 290px;
  padding-left: 20px;
  flex: none;
}
.status-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 175px;
  padding-left: 20px;
  flex: none;
  @media (max-width: 1376px) {
    width: 100px;
  }
}
.finished .requirement-column {
  border-left: 1px solid #cccccc;
}
.requirement-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  padding-left: 20px;
}
.id-column {
  border-right: 1px solid #cccccc;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 93px;
  padding-left: 20px;
  border-left: 1px solid #cccccc;
  flex: none;
}
.list-head {
  font-size: 16px;
  display: flex;
  background: linear-gradient(180deg, #37c489 0%, #00cccc 100%);
  color: white;
  width: 100%;
}
.item-grey {
  background-color: #f2f2f2;
}
</style>

<!-- АДАПТИВ -->
<style scoped lang="scss">
@media (max-width: 1200px) {
  .list {
    overflow-x: scroll;
  }

  .list-head {
    width: 1100px;
  }
  .list-content {
    width: 1100px;
  }
}
</style>
