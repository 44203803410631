<template>
  <div class="container container-center content">
    <div class="login-container">
      <div class="logo-container">
        <whiteLogo />
      </div>
      <LoginForm />
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/widgets/custom-components/forms/LoginForm";
import whiteLogo from "@/components/widgets/simple-components/logo/whiteLogo";
export default {
  name: "LoginPage",
  components: {
    LoginForm, whiteLogo
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/profile');
    }
  },
}
</script>

<style scoped>
.content {
  padding-top: 60px;
  padding-bottom: 60px;
}
.container {
  z-index: 1;
}
  .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 60px;
  }
  .login-form {
    display: flex;
    flex-direction: column;
  }
  .login-container {
    display: flex;
    flex-direction: column;
  }
</style>