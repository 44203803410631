<template>
  <div class="profile">
    <div class="profile-block">
      <div class="profile-header">Личные данные:</div>
      <div class="profile-list">
        <div class="profile-list__item">
          <organizationInformationItem
            v-if="!editMode"
            header="Ваши ФИО"
            icon="white-avatar-icon"
            :text="currentUser.name"
          />
          <inputWithHeader v-else header="Ваши ФИО" :start-text="currentUser.name" @input-handler="changeNameInput" />
        </div>
        <!--          <div class="profile-list__item">
            <organizationInformationItem header="Ваше подразделение" icon="white-plus-icon" :text="'Введите название подразделения'" v-if="!editMode"></organizationInformationItem>
            <inputWithHeader header="Ваше подразделение" :start-text="'В разработке'" v-else></inputWithHeader>
          </div>-->
        <div class="profile-list__item">
          <organizationInformationItem
            v-if="!editMode"
            header="Ваша должность"
            icon="white-shield-icon"
            :text="currentUser.post || 'Нет'"
          />
          <inputWithHeader
            v-else
            header="Ваша должность"
            :start-text="currentUser.post || ''"
            :placeholder="'Введите должность'"
            @input-handler="changePostInput"
          />
        </div>
      </div>
    </div>
    <div class="profile-block">
      <div class="profile-list">
        <div class="profile-list__item">
          <div style="font-size: 14px; color: #666666; margin-bottom: 9px;">Подразделения</div>
          <div>{{ userDepartments || 'Отсутствуют' }}</div>
        </div>
        
        <div class="profile-list__item">
          <div style="font-size: 14px; color: #666666; margin-bottom: 9px;">Отделы</div>
          <div>{{ userSubdivisions || 'Отсутствуют' }}</div>
        </div>
      </div>
    </div>
    <div class="profile-block">
      <div class="profile-header">Контактная информация:</div>
      <div class="profile-list">
        <div class="profile-list__item">
          <organizationInformationItem
            v-if="!editMode"
            header="Ваш телефон"
            icon="white-phone-icon"
            :text="currentUser.phone || 'Нет'"
          />
          <inputWithHeader
            v-else
            header="Ваш телефон"
            type="tel"
            placeholder="+7(###)###-##-##"
            :start-text="currentUser.phone"
            @input-handler="changePhoneInput"
          />
        </div>
        <div class="profile-list__item">
          <organizationInformationItem
            v-if="!editMode"
            header="Ваша электронная почта"
            icon="white-email-icon"
            :text="currentUser.email"
          />
          <inputWithHeader
            v-else
            header="Ваша электронная почта"
            :start-text="currentUser.email"
            @input-handler="changeEmailInput"
          />
        </div>
        <div class="profile-list__item">
          <organizationInformationItem v-if="!editMode" header="Ваш пароль" icon="white-lock-icon">
            <div class="information-text pointer" @click="openEditMode">Сменить пароль</div>
          </organizationInformationItem>
          <div v-else class="" style="width: 226px" />
        </div>
      </div>
    </div>
    <div class="profile-block">
      <div class="profile-header">Ассоциации:</div>
      <div class="profile-list gap-12">
        <NCheckbox
          v-if="$store.getters.isSuperAdmin"
          :disabled="!editMode"
          :default-checked="Boolean(currentUser.is_quality_commissioner)"
          @update:checked="(v) => checkboxChange('is_quality_commissioner', v)"
        >
          Уполномоченный по качеству
        </NCheckbox>
        <NCheckbox
          :default-checked="Boolean(currentUser.signature)"
          :disabled="!editMode"
          @update:checked="(v) => checkboxChange('signature', v)"
        >
          Электронная подпись
        </NCheckbox>
        <NCheckbox
          :default-checked="Boolean(currentUser.vkk)"
          :disabled="!editMode"
          @update:checked="(v) => checkboxChange('vkk', v)"
        >
          Комиссия ВКК и БМД
        </NCheckbox>
        <NCheckbox
          :default-checked="Boolean(currentUser.med_commission)"
          :disabled="!editMode"
          @update:checked="(v) => checkboxChange('med_commission', v)"
        >
          Врачебная комиссия
        </NCheckbox>
        <div class="profile-doc">
          <div class="profile-doc__text">Должностная инструкция:</div>
          <div v-if="!editMode && instruction !== ''" class="profile-doc__link">
            <div class="green-document-icon icon" />
            Скачать
          </div>
          <div v-else class="profile-doc__link pointer" @click="openInstruction">
            <div class="green-document-icon icon" />
            <template v-if="!hasInstruction"> Загрузить </template>
            <template v-else> Заменить </template>
          </div>
          <input ref="instruction" type="file" hidden @change="changeInstruction" />
        </div>
      </div>
      <div v-if="!editMode" class="buttons">
        <nextButton text="РЕДАКТИРОВАТЬ ПРОФИЛЬ" type="edit" color="white" @click-handler="openEditMode" />
      </div>
    </div>
    <div v-if="editMode" class="profile-block">
      <div class="profile-header">Изменение пароля:</div>
      <div class="profile-list">
        <div class="profile-list__item">
          <inputWithHeader
            header="Старый пароль"
            :autocomplete="false"
            type="password"
            @input-handler="changeOldPassword"
          />
        </div>
        <div class="profile-list__item">
          <inputWithHeader
            header="Новый пароль"
            :autocomplete="false"
            type="password"
            :class="{ warning: checkNewPassword, success: newPassword.length >= 6 }"
            @input-handler="changeNewPassword"
          />
        </div>
        <div class="profile-list__item">
          <inputWithHeader
            header="Подтвердить пароль"
            :autocomplete="false"
            type="password"
            :class="{ warning: checkRePassword, success: newPassword === rePassword && rePassword !== '' }"
            @input-handler="changeRepassword"
          />
        </div>
      </div>
      <div class="buttons">
        <nextButton v-if="editMode" color="green" type="next" text="СОХРАНИТЬ" @click="save" />
        <nextButton v-if="editMode" color="white" type="close" text="ОТМЕНИТЬ" @click-handler="closeEditMode" />
      </div>
    </div>
  </div>
</template>

<script>
import { NCheckbox } from 'naive-ui'
import _ from 'lodash'
import organizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import inputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'

export default {
  name: 'ProfileUser',
  components: { organizationInformationItem, nextButton, inputWithHeader, NCheckbox },
  props: {
    item: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  emits: ['change-handler'],
  data() {
    return {
      instruction: '',
      oldPassword: '',
      newPassword: '',
      rePassword: '',
      editMode: this.edit,
      userCache: _.cloneDeep(this.item),
      currentUser: _.cloneDeep(this.item)
    }
  },
  computed: {
    hasInstruction() {
      return !!this.currentUser.instruction_id
    },
    checkRePassword() {
      return this.newPassword !== this.rePassword
    },
    checkNewPassword() {
      return !(this.newPassword.length === 0 || this.newPassword.length >= 6)
    },
    userDepartments: (vm) => vm.currentUser.departments.map((dep) => dep.name).join(", "),
    userSubdivisions: (vm) => vm.currentUser.subdivisions.map((sub) => {
      return `${sub.name} (${vm.currentUser.departments.find((dep) => dep.id === sub.organization_department_id).name})`
    }).join(", "),
  },
  methods: {
    changeInstruction(e) {
      this.instruction = e.target.files[0]
    },
    openInstruction() {
      this.$refs.instruction.click()
    },
    save() {
      if (this.newPassword !== this.rePassword) {
        this.$store.dispatch('ALERT_CREATE', { text: 'Пароль и подтверждение пароля не совпадают', type: 'error' })
        return
      }
      if (this.newPassword.length !== 0) {
        if (this.newPassword.length < 6) {
          this.$store.dispatch('ALERT_CREATE', { text: 'Пароль не должен быть короче 6 символов', type: 'error' })
          return
        }
      }
      if (this.instruction !== '') {
        let data = new FormData()
        data.append('file', this.instruction)
        data.append('user_id', this.currentUser.id)
        this.$api.post('documents/upload-instruction', data).then((res) => {
          if (res.data.ok === 1) {
            this.instruction = res.data.data.document
            this.currentUser.instruction_id = this.instruction.id
            this.currentUser.instruction = this.instruction
            this.$emit('change-handler', this.currentUser)
            this.$store.dispatch('ALERT_CREATE', { text: res.data.message, type: 'success' })
          } else {
            this.$store.dispatch('ALERT_CREATE', { text: res.data.message, type: 'error' })
          }
        })
      }

      const user = {
        ..._.pick(this.currentUser, [
          'id',
          'name',
          'phone',
          'vkk',
          'med_commission',
          'signature',
          'post',
          'organization',
          'is_quality_commissioner'
        ]),
        old_password: this.oldPassword,
        password: this.newPassword
      }

      this.$api.post('responsible/set?id=' + user.id, user).then((res) => {
        if (res.data.ok === 1) {
          this.$emit('change-handler', this.currentUser)
          this.$store.dispatch('ALERT_CREATE', { text: res.data.message, type: 'success' })
        } else {
          this.$store.dispatch('ALERT_CREATE', { text: res.data.message, type: 'error' })
        }
      })
      /* if(result.data.ok === 1) {
        this.changePage(1);
        this.uploadOpened = false;
      }*/
    },
    checkboxChange(key, value) {
      this.currentUser[key] = value ? 1 : 0
    },
    changeNewPassword(val) {
      this.newPassword = val
    },
    changeOldPassword(val) {
      this.oldPassword = val
    },
    changeRepassword(val) {
      this.rePassword = val
    },
    closeEditMode() {
      this.currentUser = _.cloneDeep(this.userCache)
      this.editMode = false
    },
    changePostInput(val) {
      this.currentUser.post = val
    },
    changeEmailInput(val) {
      this.currentUser.email = val
    },
    changePhoneInput(val) {
      this.currentUser.phone = val
    },
    changeNameInput(val) {
      this.currentUser.name = val
    },
    openEditMode() {
      this.editMode = true
    }
  }
}
</script>

<style scoped>
.profile-list__item {
  padding-right: 40px;
}
.buttons {
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}

.green-document-icon {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
.profile-doc {
  /*width: 308px;*/
  font-size: 12px;
  display: flex;
}
.profile-doc__link {
  color: #1cc8ab;
  margin-left: 16px;
}
.profile-header {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  color: #333333;
  padding-bottom: 20px;
}
.information-text:hover {
  text-decoration: underline;
}
.information-text {
  color: #1cc8ab;
}
.profile-block {
  border-bottom: 1px solid #cccccc;
  padding: 20px 40px;
}
.profile-list {
  display: flex;
  /*flex-wrap: wrap;*/
  /*gap: 24px;*/
  justify-content: flex-start;
}
.profile {
  width: calc(90vw - 100px);
  max-width: 1320px;
}
</style>
