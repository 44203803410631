<template>
  <div class="tab-container">
    <div class="row d-flex flex-wrap mb-5">
      <OrganizationInformationItem
        class="col"
        icon="white-search-icon"
        header="Составляющие критерия"
        :text="requirement.requirement?.component_criteria?.text"
        bold
      />
      <OrganizationInformationItem
        class="col"
        icon="white-clock-icon"
        header="Периодичность проверки"
        :text="requirement.requirement?.period?.text"
      />
      <OrganizationInformationItem
        class="col"
        icon="white-clock-icon"
        header="Дата следующей проверки"
        :text="makePeriod"
      />
      <OrganizationInformationItem
        class="col"
        icon="white-checkmark-icon"
        header="Уровень риска"
        :text="requirement.requirement?.risk?.text"
      />
      <div class="col">
        <div class="d-flex align-items-center">
          <StatusIcon class="mr-2" size="40px" :status="requirement?.status_id" />
          <div>
            <div class="text-secondary text-sm">Текущий статус:</div>
            <div>{{ requirement?.status?.text || '–' }}</div>
          </div>
        </div>
      </div>
    </div>
    <TableInfo :table-data="tableData" />
  </div>
</template>

<script>
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import StatusIcon from '@/components/widgets/simple-components/status-icon/StatusIcon'
import TableInfo from '@/components/widgets/simple-components/table-info/TableInfo'

export default {
  name: 'MainTab',
  components: { TableInfo, StatusIcon, OrganizationInformationItem },
  props: {
    requirement: {
      type: Object,
      required: true
    }
  },
  computed: {
    makePeriod() {
      if (!this.requirement.lastCheck) return '—'

      const date = new Date(parseInt(this.requirement.lastCheck.time))
      date.setDate(date.getDate() + this.requirement.requirement.period.days)

      return this.$helper.makeDate(date)
    },
    categoriesText() {
      return (this.requirement.categories || []).map((item) => item.text).join(', ')
    },
    tableData() {
      if (!this.requirement) return []

      return [
        {
          title: 'Наименование требования',
          value: this.requirement.requirement?.text,
          bolder: true
        },
        {
          title: 'Показатель',
          value: this.requirement.indicator?.text,
          bolder: true
        },
        {
          title: 'Вид медицинской деятельности',
          value: this.requirement.medaction?.text
        },
        {
          title: 'Вид медицинской помощи',
          value: this.requirement.medhelp?.text
        },
        {
          title: 'Категории',
          value: this.categoriesText
        },
        {
          title: 'Раздел',
          value: this.requirement.process.text,
          url: '/process/' + this.requirement.process.id
        },
        {
          title: 'Функциональное требование',
          value: this.requirement.requirementFunction?.text
        },
        {
          title: 'Критерий оценки',
          value: this.requirement.criteriaAssurance?.text
        },
        {
          title: 'Описание требования',
          value: this.requirement.requirement?.description?.replaceAll('\n', '<br>'),
          bolder: true
        }
      ]
    }
  }
}
</script>

<style>
.tab-container {
  min-width: 800px;
}
</style>