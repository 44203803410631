<template>
  <div class="regular-item">
    <dropdownListWithHeader
      :start-item="medType"
      placeholder="Выберите тип мед. организации"
      :search="false"
      :list="organizationMedTypes"
      @selectHandler="medTypeSelect"
    />
  </div>
  <div class="regular-item">
    <dropdownListWithHeader
      v-if="medType !== ''"
      placeholder="Выберите раздел"
      :search="false"
      :list="medType.processes"
      @selectHandler="processSelect"
    />
  </div>
  <div class="accordion-item">
    <template v-if="process !== ''">
      <OrgAccordion
        v-if="requirements.length > 0"
        :horizontal="true"
        :selected-items="selectedItems"
        :can-select-all="true"
        :forever-opened="true"
        :items="requirements"
        :header="process.text"
        @itemClick="selectRequirement"
      />
    </template>
  </div>
</template>

<script>
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import OrgAccordion from '@/components/widgets/custom-components/accordion/OrgAccordion'
export default {
  name: 'RequirementTab',
  components: { dropdownListWithHeader, OrgAccordion },
  data() {
    return {
      requirements: [],
      organizationMedTypes: [],
      selectedItems: [],
      medType: '',
      process: ''
    }
  },
  mounted() {
    this.getMedtypes()
  },
  methods: {
    selectRequirement(item) {
      item.pause = false
      let index = this.selectedItems.indexOf(item)
      if (index === -1) {
        if (item.active) {
          this.selectedItems.push(item)
        }
      } else {
        if (!item.active) {
          this.selectedItems.splice(index, 1)
        }
      }
      this.$emit('updateSelected', this.selectedItems)
    },
    processSelect(process) {
      this.process = process
      this.$api.get('process/get-process-requirements?process_id=' + this.process.id).then((res) => {
        this.requirements = res.data.data
        this.requirements.map((item) => {
          item.text = item.base_id + ': ' + item.text
        })
        this.processName = this.process.text
      })
    },
    medTypeSelect(medType) {
      this.medType = medType
    },
    getMedtypes() {
      this.$api.get('organization/get-organization-medtypes-with-processes').then((res) => {
        if (res.data.ok === 1) {
          this.organizationMedTypes = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>
.regular-item {
  padding-bottom: 10px;
}
.accordion-item {
  padding-bottom: 10px;
  max-height: 200px;
  overflow-y: auto;
}
</style>
