import AdminPage from '@/components/pages/AdminPage/AdminPage'
import MainPage from '@/components/pages/MainPage/MainPage'
import ProfilePage from '@/components/pages/ProfilePage/ProfilePage'
import NotificationPage from '@/components/pages/NotificationsPage/NotificationPage'
import InspectionPage from '@/components/pages/InspectionPage/InspectionPage'
import CreateInspection from '@/components/pages/InspectionPage/CreateInspection'
import InspectionView from '@/components/pages/InspectionPage/InspectionView'
import ControlPage from '@/components/pages/Control/ControlPage'
import ControlCreatePage from '@/components/pages/Control/ControlCreatePage'
import CreateCheckListPage from '@/components/pages/Audition/CreateCheckListPage'
import ProcessPage from '@/components/pages/ProcessPage/ProcessPage'
import ProcessesPage from '@/components/pages/ProcessPage/ProcessesPage'
import DocumentsPage from '@/components/pages/Documents/DocumentsPage'
import ActionsPage from '@/components/pages/Actions/ActionsPage'
import ActionView from '@/components/pages/Actions/ActionView'
import ActionEdit from '@/components/pages/Actions/ActionEdit'
import ActionCreate from '@/components/pages/Actions/ActionCreate'
import Organization from '@/pages/Organization'
import RequirementPage from '@/components/pages/RequirementPage/RequirementPage'
import CheckListPage from '@/components/pages/Audition/CheckListPage'
import CheckListReportPage from '@/components/pages/Audition/CheckListReportPage'
import Audition from '@/components/pages/Audition/AuditionPage'
import Videos from '@/modules/videos'

import Incidents from '@/components/pages/Incidents/List'
import IncidentsCreate from '@/components/pages/Incidents/Create'
import IncidentsView from '@/components/pages/Incidents/View'
import IncidentsEdit from '@/components/pages/Incidents/Edit'

import Risks from '@/components/pages/Risks/List'
import RisksCreate from '@/components/pages/Risks/Create'
import RisksView from '@/components/pages/Risks/View'
import RisksEdit from '@/components/pages/Risks/Edit'

export default [
  {
    path: '/admin',
    name: 'AdminPage',
    component: AdminPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/statistic',
    name: 'Home',
    component: MainPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/',
    name: 'Main',
    component: MainPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfilePage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/notifications',
    name: 'NotificationPage',
    component: NotificationPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/inspection',
    name: 'Inspection',
    component: InspectionPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/inspection/create/',
    name: 'Create inspection',
    component: CreateInspection,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/inspection/:id',
    name: 'InspectionView',
    component: InspectionView,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/control/:id',
    name: 'ControlPage',
    component: ControlPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/control/edit/:id',
    name: 'ControlEditPage',
    component: ControlCreatePage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/control/create/:date&:process_id',
    name: 'ControlCreatePage',
    component: ControlCreatePage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/audition/create/',
    name: 'Create checklist',
    component: CreateCheckListPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/process/:id',
    name: 'Process',
    component: ProcessPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/processes',
    name: 'Processes',
    component: ProcessesPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/documents/:tab',
    name: 'Documents Tab',
    component: DocumentsPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/actions',
    name: 'Actions list page',
    component: ActionsPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/actions/:id',
    name: 'Action view page',
    component: ActionView,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/actions/:id/edit',
    name: 'Action edit page',
    component: ActionEdit,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/actions/create',
    name: 'Action create page',
    component: ActionCreate,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/organization/:tab',
    name: 'Organization',
    component: Organization,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/requirement/:id',
    name: 'Requirement',
    component: RequirementPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/checklist/:number',
    name: 'Checklist',
    component: CheckListPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/checklist/report/:id',
    name: 'ChecklistReport',
    component: CheckListReportPage,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/audition',
    name: 'Audition',
    component: Audition,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/videos',
    name: 'Videos',
    component: Videos,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/incidents',
    component: Incidents,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/incidents/create',
    component: IncidentsCreate,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/incidents/:id',
    component: IncidentsView,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/incidents/:id/edit',
    component: IncidentsEdit,
    meta: { layout: 'UserLayout' }
  },

  {
    path: '/risks',
    component: Risks,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/risks/create',
    component: RisksCreate,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/risks/:id',
    component: RisksView,
    meta: { layout: 'UserLayout' }
  },
  {
    path: '/risks/:id/edit',
    component: RisksEdit,
    meta: { layout: 'UserLayout' }
  },
  // {
  //   path: '/risks',
  //   component: <div>risks</div>,
  //   meta: { layout: 'UserLayout' }
  // }
]
