import { createStore } from 'vuex'
import { auth } from './auth.module'
import organization from './store_modules/organization'
import process from './store_modules/process'
import vkk from './store_modules/vkk'
import documents from './store_modules/documents'
import action from './store_modules/action'
import risks from './store_modules/risks'
import checklist from './store_modules/checklist'
import inspection from './store_modules/inspection'
import requirement from './store_modules/requirement'
import common_states from './common_states/common_states'
import user from './store_modules/user'
import control from './store_modules/control'
import notifications from '@/store/store_modules/notifications'
import alert from '@/store/store_modules/alert'

const store = createStore({
  modules: {
    auth,
    organization,
    common_states,
    process,
    vkk,
    requirement,
    checklist,
    action,
    documents,
    user,
    control,
    notifications,
    alert,
    inspection,
    risks,
  }
})

export default store
