<template>
  <div class="item">
    <router-link class="number-row" :to="'/inspection/' + item.id">{{ item.number }}</router-link>
    <div class="status-row">{{ item.status?.name }}</div>
    <div class="auditor-row">{{ item.queue?.name }}</div>
    <div class="type-row">{{ item.type?.name }}</div>
    <div class="date-row">{{ formatedDatetime }}</div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    item: {
      type: Object,
      require: true
    }
  },
  data() {
    return {}
  },
  computed: {
    formatedDatetime() {
      return moment(this.item.datetime).format('DD.MM.YYYY')
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.item {
  border: 1px solid #cccccc;
  font-size: 14px;
  line-height: 16px;
  color: #666666;
  display: flex;
  width: 100%;
}

.number-row {
  border-right: 1px solid #cccccc;
  width: 15%;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  flex: none;
}

.status-row {
  width: 22%;
  padding-top: 9px;
  padding-bottom: 9px;
  flex: none;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.auditor-row {
  width: 21%;
  flex: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.type-row {
  width: 21%;
  flex: none;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-left: 20px;
  border-right: 1px solid #cccccc;
}

.date-row {
  width: 21%;
  flex: none;
  padding-left: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
  border-right: 1px solid #cccccc;
}
</style>
