<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useDialog, NAlert } from 'naive-ui'
import { isEmpty } from 'lodash'
import ViewTabMain from '@/components/pages/Incidents/ViewTabMain'
import Documents from '@/components/pages/Incidents/ViewTabDocuments'
import ViewTabActions from '@/components/pages/Incidents/ViewTabActions'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import SelectProcessModal from '@/components/pages/Actions/actionComponents/selectProcessModal'
import { useIncidentsStore } from '@/store/incidents'
import useTarifExtension from '@/composables/useTarifExtension'

const { hasPermission } = useTarifExtension()
const route = useRoute()
const router = useRouter()
const dialog = useDialog()
const incidentsStore = useIncidentsStore()
const { isLoading, isError, item: incident } = storeToRefs(incidentsStore)

const selectProcessModalRef = ref(null)

const currentTab = ref('main')

const incidentId = computed(() => route.params.id)

onMounted(() => {
  incidentsStore.fetchOne(incidentId.value)
})

function changeTab(tab = 'main') {
  currentTab.value = tab
}

function getToWork() {
  const d = dialog.success({
    title: 'Подтвердите действие',
    positiveText: 'Да',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return incidentsStore.updateStatus(incidentId.value, 2)
    }
  })
}

function closeHandler() {
  const d = dialog.error({
    title: 'Подтвердите действие',
    positiveText: 'Да',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return incidentsStore.updateStatus(incidentId.value, 3)
    }
  })
}

function deleteHandler() {
  const d = dialog.error({
    title: 'Подтвердите действие',
    positiveText: 'Да',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return incidentsStore.deleteById(incidentId.value).then(res => {
        if (res.status === 200) {
          router.push('/incidents')
        }
      })
    }
  })
}

// function changeStatusHandler(btn) {
//   if (
//     !event.value.checklist &&
//     (!event.value?.action?.process_id || !event.value?.action?.action_category_id) &&
//     event.value.action.status_id === 4 &&
//     btn.name !== 'Доработать' &&
//     btn.name !== 'Отмена'
//   ) {
//     selectProcessModalRef.value?.openModal(event.value.action, ({ med_type_id, process_id, action_category_id }) =>
//       eventsStore
//         .update(
//           {
//             ...event.value.action,
//             med_type_id,
//             process_id,
//             action_category_id
//           },
//           false
//         )
//         .then(() => {
//           eventsStore.updateStatus(incidentId.value, btn.status_id)
//         })
//     )
//   } else {
//     const d = dialog.warning({
//       title: 'Подтвердите действие',
//       content: 'Вы действительно хотите изменить статус мероприятия?',
//       positiveText: 'Изменить статус',
//       negativeText: 'Отмена',
//       onPositiveClick: async () => {
//         d.loading = true
//         try {
//           await eventsStore.updateStatus(incidentId.value, btn.status_id)
//         } catch (e) {
//           console.log(e)
//         }
//       }
//     })
//   }
// }
</script>

<template>
  <SpinLoader v-if="isLoading && !isError" class="py-5" size="2xl" />
  <template v-else-if="!isLoading && !isError && incident">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__text">
          <div class="page-header__heading">Нежелательное событие #{{ incidentId }}</div>
          <div class="page-header__second-heading">{{ incident?.medtype?.text || '' }}</div>
        </div>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <!-- <template v-if="statusBtns">
            <NextButton
              v-for="(btn, key) in topStatusBtns"
              :key="key"
              :text="btn.name"
              type=""
              @click="changeStatusHandler(btn)"
            />
          </template> -->

          <!-- если статус НОВОЕ -->
          <NextButton v-if="incident.status == 1" type="next" text="Взять в работу" @click="getToWork" />
          <!-- если статус В_РАБОТЕ -->
          <NextButton v-if="incident.status == 2" type="next" text="Закрыть" @click="closeHandler" />

          <NextButton :to="`/incidents/${incidentId}/edit`" type="edit" text="Редактировать" color="white" />
          <NextButton
            type="close"
            text="Удалить"
            color="red"
            @click="deleteHandler"
          />
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="tab pointer" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">
        Общая информация
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'actions' }" @click="changeTab('actions')">
        Мероприятия
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'documents' }" @click="changeTab('documents')">
        Документы
      </div>
    </div>
    <div class="tab-content">
      <ViewTabMain v-if="currentTab === 'main'" :data="incident" />
      <ViewTabActions v-if="currentTab === 'actions'" :data="incident" />

      <div v-else-if="currentTab === 'documents'">
        <NAlert v-if="!hasPermission(18)" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
          Для получения полного доступа необходимо сменить тариф
        </NAlert>
        <div :class="{ 'disabled-by-tarif': !hasPermission(18) }">
          <Documents type="action" :document-id="incident?.action?.id" />
        </div>
      </div>
    </div>
    <!-- <NextButton
      v-if="statusBtns?.cancel_btn"
      class="mt-4"
      color="red"
      type=""
      :text="statusBtns?.cancel_btn?.name"
      @click="changeStatusHandler(statusBtns?.cancel_btn)"
    /> -->
  </template>
  <NextAlert v-if="isError && !isLoading" type="error" :text="isError" />
  <SelectProcessModal ref="selectProcessModalRef" />
</template>
