<template>
  <div>
    <div class="d-flex flex-wrap mb-3">
      <OrganizationInformationItem header="Ответственный" icon="white-avatar-icon" :text="data.responsible.name" />
      <OrganizationInformationItem header="Вероятность риска" icon="white-plus-icon" :text="data.probability" />
      <OrganizationInformationItem header="Тяжесть риска" icon="white-plus-icon" :text="data.severity" />
      <OrganizationInformationItem header="Уровень риска" :text="data.level" />
    </div>
    <div class="border-2 border-primary mb-4 px-4">
      <TableInfo :table-data="primaryTableData" />
    </div>

    <TableInfo :table-data="tableData" />
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import TableInfo from '@/components/widgets/simple-components/table-info/TableInfo'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import { NCheckbox } from 'naive-ui'

export default {
  components: { NextAlert, TableInfo, OrganizationInformationItem, NCheckbox },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      action: this.data.action
    }
  },
  computed: {
    tableData() {
      const data = [
        {
          title: 'Владелец риска',
          value: this.data.owner
        },
        {
          title: 'Подразделение',
          value: this.data.department?.name || ''
        },
        {
          title: 'Отдел',
          value: this.data.subdivision?.name || ''
        },
        {
          title: 'Направление',
          value: this.data.medType?.text
          // url: this.getProcessUrl()
        },
        {
          title: 'Раздел',
          value: this.data.process?.text
        },
        {
          title: 'Наименование риска',
          value: this.data.actionCategory?.name
        },
        {
          title: 'Вид риска',
          value: this.data.type
        }
      ]

      return data
    },
    primaryTableData() {
      return [
        {
          title: 'Описание',
          value: this.data.description,
          bolder: true
        },
        {
          title: 'Причины возникновения риска',
          value: this.data.causes,
          bolder: true
        },
        {
          title: 'Последствия риска',
          value: this.data.consequence,
          bolder: true
        },
        {
          title: 'Мероприятия по управлению риском',
          value: this.data.activities,
          bolder: true,
        },
        {
          title: 'План мероприятий',
          value: this.data.activities_plan,
          bolder: true,
        },
        {
          title: 'Периодичность мониторинга',
          value: this.data.frequency,
          bolder: true,
        },
      ]
    }
  },
  watch: {
    data(v) {
      // const process_id = this.action.process_id

      // this.action = {
      //   ...v?.action,
      //   process: this.action.process,
      //   action_category: this.action.action_category
      // }

      // if (v.action.process_id && process_id !== v.action.process_id) {
      //   this.fetchProcess()
      // }

      // if (v.action.action_category_id) {
      //   this.fetchActionCategory()
      // }
    }
  },
  mounted() {
    // if (this.action.process_id) {
    //   this.fetchProcess()
    // }
    // if (this.action.action_category_id) {
    //   this.fetchActionCategory()
    // }
  },
  methods: {
    // async fetchProcess() {
    //   const res = await this.$api.get(`process/get?id=${this.action.process_id}`)
    //   this.action.process = res.data.data
    // },
    // async fetchActionCategory() {
    //   const res = await this.$api.get(`refbook/get?id=${this.action.action_category_id}`)
    //   this.action.action_category = res.data.data[0]
    // },
    // getProcessUrl() {
    //   const id = this.data.process?.id || this.action.process_id
    //   return id ? `/process/${id}` : null
    // }
  }
}
</script>

<style lang="scss" scoped>
.information-item {
  padding-right: 1.5rem;
  margin-bottom: 2rem;
  width: auto;

  &:last-child {
    padding-right: 0;
  }
}
</style>
