<template>
  <SpinLoader v-if="isLoading && !isError" class="py-5" size="2xl" />
  <div class="page-header">
    <div class="header-text">
      <template v-if="mode === 'create'">Создать заседание ВКК</template>
      <template v-if="mode === 'edit'">Редактировать заседание ВКК</template>
    </div>
    <div class="page-controls">
      <nextButton text="Сохранить" :class="{ inactive: !checkSave }" @click="save" />
      <nextButton text="Удалить" type="close" color="red" @click="deleteControl" />
    </div>
  </div>
  <div class="tabs">
    <div class="tab pointer" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">
      Общая информация
    </div>
    <div
      class="tab pointer"
      :class="{ active: currentTab === 'documents', inactive: !checkSave }"
      @click="changeTab('documents')"
    >
      Документы
    </div>
  </div>
  <div v-if="currentTab === 'main'" class="tab-content">
    <div class="content-row">
      <div class="content-column w100">
        <div class="subheader" style="font-weight: 600">
          Раздел:
          <router-link :to="'/process/' + formData.process.id">
            <span style="font-weight: 400">{{ formData.process.text }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-column">
        <InputWithHeader
          v-if="formData.dateStart !== false"
          header="Дата начала"
          type="date"
          :start-text="formData.dateStart"
          required
          @inputHandler="(v) => changeValue(v, 'dateStart')"
        />
      </div>
      <div class="content-column">
        <InputWithHeader
          v-if="formData.dateEnd !== false"
          header="Дата завершения"
          type="date"
          :start-text="formData.dateEnd"
          required
          @inputHandler="(v) => changeValue(v, 'dateEnd')"
        />
      </div>
      <div class="content-column">
        <InputWithHeader
          v-if="formData.commissionDate !== false"
          :start-text="formData.commissionDate"
          header="Дата заседания комиссии ВКК и БМД"
          type="date"
          @inputHandler="(v) => changeValue(v, 'commissionDate')"
        />
      </div>
      <div class="content-column">
        <dropdownListWithHeader
          :key="formData.assurance?.id"
          :start-item="formData.assurance"
          header="Вид проверки"
          placeholder="Выберите вид проверки"
          required
          :search="false"
          :list="assurances"
          @selectHandler="(v) => changeValue(v, 'assurance')"
        />
      </div>
    </div>
    <div class="content-row">
      <div class="content-column big">
        <InputWithHeader
          :key="formData.commentary !== false"
          header="Комментарий"
          placeholder="Комментарий"
          :start-text="formData.commentary"
          type="textarea"
          @inputHandler="(v) => changeValue(v, 'commentary')"
        />
      </div>
    </div>
  </div>
  <Documents
    v-if="currentTab === 'documents'"
    :list="$store.getters.controlDocuments"
    type="control"
    :document-id="formData.id"
  />
</template>

<script>
import nextButton from '@/components/widgets/simple-components/buttons/nextButton'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import InputWithHeader from '@/components/widgets/simple-components/inputs/inputWithHeader'
import dropdownListWithHeader from '@/components/widgets/simple-components/inputs/dropdown/dropdownListWithHeader'
import Documents from '@/components/widgets/custom-components/tabs/organization-tabs/Documents'
export default {
  name: 'ControlCreatePage',
  components: { SpinLoader, InputWithHeader, dropdownListWithHeader, nextButton, Documents },
  data() {
    return {
      assurances: [],
      mode: 'create',
      documentsList: [],
      formData: {
        process: '',
        assurance: '',
        dateEnd: false,
        dateStart: false,
        commissionDate: false,
        commentary: false
      },
      isLoading: false,
      isError: false,
      currentTab: 'main'
    }
  },
  computed: {
    checkSave() {
      if (this.formData.dateStart === '') {
        return false
      }
      if (this.formData.dateEnd === '') {
        return false
      }
      if (this.formData.assurance == '' || this.formData.assurance == null) {
        return false
      }
      return true
    }
  },
  /* watch: {
    formData: {
      handler(v) {
        this.$emit('changeValue', this.createExportObject(v))
      },
      deep: true,
      immediate: true
    }
  },*/
  beforeMount() {
    this.$api.get('/assurances/get-all').then((res) => {
      if (res.data.ok === 1) {
        this.assurances = res.data.data
      }
    })
    if (this.$router.currentRoute.value.path.indexOf('/control/edit') === 0) {
      this.editInit()
    } else {
      this.createInit()
    }
  },
  mounted() {},
  methods: {
    createInit() {
      this.mode = 'create'
      let processId = this.$router.currentRoute.value.params.process_id
      this.$api.get('process/get?id=' + processId).then((res) => {
        if (res.data.ok === 1) {
          this.formData.process = res.data.data
        }
        this.dateEnd()
        this.dateStart()
        this.commissionDate()
        this.formData.commentary = ''
      })
    },
    editInit() {
      this.mode = 'edit'
      this.isLoading = true
      this.$api.get('control/get?id=' + this.$router.currentRoute.value.params.id).then((res) => {
        this.isLoading = false
        if (res.data.ok === 1) {
          this.formData = {
            id: res.data.data.id,
            process: res.data.data.process,
            assurance: res.data.data.assurance,
            dateEnd: res.data.data.finish_date,
            dateStart: res.data.data.start_date,
            commissionDate: res.data.data.commission_date,
            commentary: res.data.data.commentary
          }
        } else {
          this.$router.push('/inspection')
        }
      })
    },
    formatFormData() {
      let dateStart = new Date(this.formData.dateStart).getTime() / 1000
      let finishDate = new Date(this.formData.dateEnd).getTime() / 1000
      let data = {
        id: this.formData.id,
        process_id: this.formData.process.id,
        commentary: this.formData.commentary,
        start_date: dateStart,
        finish_date: finishDate,
        assurance_id: this.formData.assurance.id
      }
      if (this.formData.commissionDate != '') {
        data.commission_date = new Date(this.formData.commissionDate).getTime() / 1000
      } else {
        data.commission_date = null
      }
      return data
    },
    saveAndGo(tab) {
      if (this.checkSave) {
        this.$api.post('control/save', this.formatFormData()).then((res) => {
          if (res.data.ok === 1) {
            this.formData.id = res.data.data.id
            this.currentTab = tab
          }
        })
      }
    },
    deleteControl() {
      let id = this.formatFormData().id
      if (id) {
        this.$api.post('control/delete', id).then((res) => {
          if (res.data.ok === 1) {
            this.$router.push('/inspection')
          }
        })
      } else {
        this.$router.push('/inspection')
      }
    },
    save() {
      if (this.checkSave) {
        this.$api.post('control/save', this.formatFormData()).then((res) => {
          if (res.data.ok === 1) {
            this.$router.push('/control/' + res.data.data.id)
          }
        })
      }
    },
    currentDate() {
      let date = this.$router.currentRoute.value.params.date.split('-')
      let month = Number.parseInt(date[1] - 1)
      let day = Number.parseInt(date[0])
      let year = Number.parseInt(date[2])
      return new Date(year, month, day)
    },
    createExportObject(values) {
      values.act_date = this.$helper.toTimestamp(values.act_date)
      values.plan_date = this.$helper.toTimestamp(values.plan_date)
      return values
    },
    changeValue(val, type) {
      this.formData[type] = val
    },
    commissionDate() {
      if (this.mode === 'create') {
        this.formData.commissionDate = this.currentDate()
        this.formData.commissionDate.setDate(this.formData.commissionDate.getDate() + 14)
        let month = this.formData.commissionDate.getMonth() + 1
        let year = this.formData.commissionDate.getFullYear()
        let day = this.formData.commissionDate.getDate()
        if (month < 10) {
          month = '0' + month
        }
        if (day < 10) {
          day = '0' + day
        }
        this.formData.commissionDate = year + '-' + month + '-' + day
      }
    },
    dateStart() {
      if (this.mode === 'create') {
        let currentDate = this.currentDate()
        let month = currentDate.getMonth() + 1
        let year = currentDate.getFullYear()
        let day = currentDate.getDate()
        if (month < 10) {
          month = '0' + month
        }
        if (day < 10) {
          day = '0' + day
        }
        this.formData.dateStart = year + '-' + month + '-' + day
      }
    },
    dateEnd() {
      if (this.mode === 'create') {
        this.formData.dateEnd = this.currentDate()
        this.formData.dateEnd.setDate(this.formData.dateEnd.getDate() + 13)
        let month = this.formData.dateEnd.getMonth() + 1
        let year = this.formData.dateEnd.getFullYear()
        let day = this.formData.dateEnd.getDate()
        if (month < 10) {
          month = '0' + month
        }
        if (day < 10) {
          day = '0' + day
        }
        this.formData.dateEnd = year + '-' + month + '-' + day
      }
    },
    changeTab(tab) {
      if (!this.checkSave) {
        return
      }
      if (!this.formData.id && tab === 'documents') {
        this.saveAndGo('documents')
        return
      }
      this.currentTab = tab
    }
  }
}
</script>

<style scoped>
.tab.inactive {
  opacity: 0.6;
  cursor: not-allowed;
}
.subheader {
  font-size: 18px;
}
.content-column.big {
  width: 100%;
}
.content-column {
  width: 400px;
  padding-right: 20px;
}
.content-row {
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
}
.page {
  filter: drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.125));
}
.content-block {
  background-color: white;
  border-radius: 0px 3px 3px 3px;
  padding: 40px;
}
.page-controls {
  display: flex;
  width: 370px;
  justify-content: space-between;
}
</style>
