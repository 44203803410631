<template>
  <div>
    <div class="d-flex flex-wrap mb-3">
      <div class="d-flex justify-content-between mb-4 w-100">
        <div class="d-flex flex-wrap gap-3">
          <!-- <div class="dropdown ">
            <p>Период</p>
            <div class="d-flex align-items-center">
              <input class="date-input" type="date" @change="changeStartDate">
              <div class="filter-delimiter">-</div>
              <input class="date-input" type="date" @change="changeStopDate">
            </div>
          </div>
          <div class="dropdown">
            <p>Статус</p>
            <NSelect
              size="large"
              clearable
              style="width: 280px"
              :options="incidentsTypes"
              :on-update:value="incidentsTypeSelect"
            />
          </div> -->
        </div>
        <!-- <RouterLink :to="riskCreateLink">
          <NButton type="primary"> Регистрация НС </NButton>
        </RouterLink> -->
      </div>
      <NextTable
        class="table-responsive-xl"
        :columns="columns"
        :table-data="tableData"
        :loading="isLoading"
        striped
        @change:sort="changeSort"
      >
        <template #id="{ value }">
          <RouterLink :to="`/incidents/${value}`">{{ value }}</RouterLink>
        </template>

        <template #registration_date="{ value }">
          <!-- <RouterLink :to="`/incidents/${row.id}`" class="!text-gray-800">
            {{ value }}
          </RouterLink> -->
          <span>{{ value }}</span>
        </template>

        <template #department="{ value }">
          <span>{{ value?.name || "—" }}</span>
        </template>

        <template #place="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #med_type_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #process_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #action_category_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #description="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #consequence="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #measures="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #registrator="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #responsible_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #status="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #elimination_date="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #reaction_index="{ value, row }">
          <div class="flex">
            <div v-if="value == 0" style="background: #717476; width: 20px; height: 20px; border-radius: 100%;" />
            <div v-else style="background: #dc3545; width: 20px; height: 20px; border-radius: 100%;" />
            <div class="pl-2">{{ row.reaction_index_text }}</div>
          </div>
        </template>

        <template #sending_date_rzn="{ value }">
          <span>{{ value }}</span>
        </template>
      </NextTable>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import moment from 'moment'
import { NButton, NSelect } from 'naive-ui'
import { useIncidentsStore } from '@/store/incidents'
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import { computed } from 'vue'

const route = useRoute()
const riskId = computed(() => route.params.id)

const columns = [
  { key: 'id', title: '№', width: '80px', sortable: true, sortOrder: 'DESC', fontSize: '0.7rem', padding: '10px' },
  {
    key: 'registration_date',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата регистрации',
    render: (row, item) => moment(item).format('DD.MM.YYYY')
  },
  {
    key: 'department',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Подразделение'
  },
  {
    key: 'place',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Место'
  },
  {
    key: 'medType',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Направление',
    render: (row, item) => item?.text
  },
  {
    key: 'process',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Раздел ВКК',
    render: (row, item) => item?.text
  },
  {
    key: 'actionCategory',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Вид нежелательного события',
    render: (row, item) => item?.name
  },
  {
    key: 'description',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Описание'
  },
  {
    key: 'consequence',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Последствия'
  },
  {
    key: 'measures',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Принятые меры'
  },
  {
    key: 'registrator',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Кто зарегистрировал',
    render: (row, item) => item.name
  },
  {
    key: 'responsible',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Ответственный',
    render: (row, item) => item.name
  },
  {
    key: 'statusName',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Статус'
  },
  {
    key: 'elimination_date',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата устранения'
  },
  {
    key: 'reaction_index',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Индекс реагирования',
  },
  {
    key: 'sending_date_rkn',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата извещения',
    render: (row, item) => item ? moment(item).format('DD.MM.YYYY') : ''
  }
]

const incidentsStore = useIncidentsStore()
const incidentsStoreRefs = storeToRefs(incidentsStore)
const tableData = incidentsStoreRefs.list

onMounted(() => {
  incidentsStore.fetch({ risk_id: riskId.value })
})

const riskCreateLink = computed(() => {
  return `/incidents/create?risk_id=${riskId.value}`
})
</script>

<style lang="scss" scoped>

</style>
