<template>
  <div class="accordion">
    <div class="accordion-header">
      <input type="checkbox" hidden>
      <div class="accordion-checkbox" :class="{pointer: canSelectAll}" @click="toggleAll">
        <div v-if="active === 1" class="checkbox-center" />
        <div v-if="active === 2" class="checked-icon" />
      </div>
      <div class="accordion-header__text pointer" @click="listOpen">{{ header }}</div>
      <div v-if="!foreverOpened" class="accordion-open" @click="listOpen">
        <div v-if="!openList" class="open-plus-icon icon" />
        <div v-if="openList" class="open-minus-icon icon" />
      </div>
    </div>
    <div v-show="openList" class="accordion-list">
      <div
        v-for="(item, key) in accordionItems" 
        :key="key" 
        class="accordion-item"
        :title="createTitle(item)" :class="{pause: item.pause, horizontal: horizontal}" @click="itemClick(item)"
      >
        <div class="accordion-item-checkbox">
          <div v-if="item.active" class="checked-icon" />
        </div>
        <div class="accordion-item__text">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrgAccordion',
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false;
      }
    },
    horizontal: {
      type: Boolean,
      default() {
        return false;
      }
    },
    pause: {
      type: Boolean,
      default() {
        return false
      }
    },
    canSelectAll: {
      type: Boolean,
      default() {
        return false;
      }
    },
    foreverOpened: {
      type: Boolean,
      default() {
        return false;
      }
    },
    items: {
      type: Array,
      required: true
    },
    selectedItems: {
      type: Array,
      default() {
        return [];
      }
    },
    header: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      openList: this.foreverOpened
    }
  },
  computed: {
    accordionItems() {
      let currentItems = this.selectedItems
      let items = []
      for (let i = 0; i < this.items.length; i++) {
        items.push(this.items[i])
        for (let n = 0; n < currentItems.length; n++) {
          if (this.items[i].id == currentItems[n].process_id) {
            items[i].active = true
          }
        }
      }
      return items
    },
    active() {
      let currentItems = this.selectedItems
      let count = 0;
      let result = 0;

      for (let i = 0; i < this.accordionItems.length; i++) {
        for (let n = 0; n < currentItems.length; n++) {
          if(currentItems[n].process_id) {
            if (this.accordionItems[i].id == currentItems[n].process_id) {
              count++;
              result = 1;
            }
          } else {
            if (this.accordionItems[i].id == currentItems[n].id) {
              count++;
              result = 1;
            }
          }
        }
      }
      if(this.accordionItems.length && this.accordionItems.length === count) {
        result = 2;
      }
      return result
    }
  },
  methods: {
    listOpen() {
      if(this.foreverOpened) {
        return;
      }
      this.openList = !this.openList;
    },
    toggleAll() {
      if(!this.canSelectAll) {
        return false;
      }
      let currentFunc = '';
      if(this.active === 0) {
        currentFunc = this.itemSelect;
      } else {
        currentFunc = this.itemUnselect;
      }
        for(let i = 0; i < this.accordionItems.length; i++) {
          let item = this.accordionItems[i];
          currentFunc(item);
        }
    },
    createTitle(item) {
      if (item.pause) {
        return 'Запрос выполняется, пожалуйста, подождите'
      }
      return ''
    },
    itemSelect(item) {
      if (!item.pause) {
        item.active = true
        item.pause = true
        this.$emit('itemClick', item)
      }
    },
    itemUnselect(item) {
      if (!item.pause) {
        item.active = false
        item.pause = true
        this.$emit('itemClick', item)
      }
    },
    itemClick(item) {
      if (!item.pause) {
        item.active = !item.active
        item.pause = true
        this.$emit('itemClick', item)
      }
    }
  }
}
</script>

<style scoped>
.accordion-item-checkbox {
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.accordion-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.accordion-item.horizontal {
  width: 100%;
}
.accordion-item.pause {
  opacity: .6;
  cursor: default;
}

.accordion-item {
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
  width: 50%;
  padding-right: 40px;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 10px;
  color: #333333;
}

.checkbox-center.full {
  background: transparent;
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.24899 9.97863C4.44655 9.98201 4.63779 9.90938 4.7822 9.77453C6.99878 7.55794 8.12286 6.43335 9.22275 5.33296C10.2767 4.27855 11.3084 3.24637 13.2578 1.29699C13.3926 1.15258 13.4653 0.961336 13.4619 0.763783C13.4585 0.566231 13.3789 0.378104 13.2392 0.238393C13.0995 0.0986815 12.9114 0.0181399 12.7138 0.0147599C12.5163 0.0113799 12.325 0.084988 12.1806 0.219838C10.8576 1.54286 9.93719 2.46244 9.12997 3.26892C7.68675 4.71082 6.60541 5.79118 4.23194 8.16938C2.79553 6.73266 3.04361 6.9791 3.16468 7.09966C3.19964 7.13451 3.21038 7.1452 3.207 7.14183C3.20362 7.13846 3.18615 7.12104 3.16468 7.09966C3.03572 6.9711 2.57716 6.5138 1.28136 5.22081C1.13695 5.08596 0.945712 5.01236 0.748159 5.01574C0.550607 5.01912 0.36248 5.09966 0.222769 5.23937C0.0830575 5.37908 0.00349291 5.56721 0.000112878 5.76476C-0.00326715 5.96231 0.0693639 6.15355 0.204214 6.29796C2.42098 8.51473 2.24144 8.33468 2.06573 8.15847C1.89738 7.98964 1.73254 7.82434 3.68175 9.77355C3.82616 9.9084 4.0174 9.98103 4.21496 9.97765C4.21647 9.97762 4.21798 9.97759 4.21949 9.97755C4.2293 9.9781 4.23913 9.97846 4.24899 9.97863Z' fill='url(%23paint0_linear_191_3310)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_191_3310' x1='6.73099' y1='0.0146484' x2='6.73099' y2='9.97874' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2337C489'/%3E%3Cstop offset='1' stop-color='%2300CCCC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
}
.checkbox-center {
  width: 10px;
  height: 10px;
  background: linear-gradient(180deg, #37C489 0%, #00CCCC 100%);
  border-radius: 2px;
}

.accordion-checkbox {
  margin-right: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px;
}

.accordion-header__text {
  width: 100%;
}

.accordion-open {
  background: linear-gradient(180deg, #37C489 0%, #00CCCC 100%);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.accordion-header {
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 3px;
  align-items: center;
  padding: 10px 20px;
  font-weight: 400;
  display: flex;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
}

</style>