<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { NButton, NH1 } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useIncidentsStore } from '@/store/incidents'

import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import CreateForm from './CreateForm'
import moment from 'moment'

const route = useRoute()
const router = useRouter()

const incidentsStore = useIncidentsStore()
const { isLoading, isError, item: incident } = storeToRefs(incidentsStore)

const formRef = ref(null)

const incidentId = computed(() => route.params.id)

onMounted(() => {
  incidentsStore.fetchOne(incidentId.value)
})

async function save() {
  formRef.value?.validate((errors, values) => {
    console.log('%c LOG: ', 'background: #9488ea; color: #f1f1f1; font-size: 12px; border-radius: 4px', errors)
    if (errors) return

    const payload = {
      ...values,
      id: Number(incidentId.value),
      is_notice: Number(values.is_notice),
      registration_date: values.registration_date ? moment(values.registration_date).format('YYYY-MM-DD') : null,
      sending_date_rkn: values.sending_date_rkn ? moment(values.sending_date_rkn).format('YYYY-MM-DD') : null,
    }

    incidentsStore.update(incidentId.value, payload).then((res) => {
      router.push('/incidents/' + res.data.id)
    })
  })
}

function cancel() {
  router.back()
  // router.push(route.query?.checklist ? `/checklist/${route.query.checklist}` : '/actions/')
}
</script>

<template>
  <div class="max-w-6xl mx-auto">
    <div class="page-header">
      <div class="page-header__left">
        <NH1 class="mb-0">Редактирование нежелательного события</NH1>
      </div>
      <div class="page-header__right">
        <div class="page-header__controls">
          <NButton type="primary" :loading="isSaving" @click="save">Сохранить</NButton>
          <NButton :disabled="isSaving" strong secondary @click="cancel">Отмена</NButton>
        </div>
      </div>
    </div>
    <div class="content">
      <NextAlert v-if="isError && !isLoading" class="mb-4" type="error" :text="error" />
      <SpinLoader v-else-if="isLoading" class="py-5" size="2xl" />
      <div v-else class="max-w-200">
        <CreateForm ref="formRef" :data="incident" is-edit />
      </div>
    </div>
  </div>
</template>

<style scoped>
.content {
  padding: 40px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.125);
}
</style>
