<script setup>
import { computed, reactive, ref, watch, onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import moment from 'moment'
import { NForm, NFormItem, NInput, NDatePicker, NCheckbox, NSelect, NRadioGroup, NRadioButton } from 'naive-ui'
import EntrySelect from '@/components/common/EntitySelect'
import { serialize } from '@/utils/url'
import api from '@/api'
import { REQUIRED_FIELD_MESSAGE } from '@/constants'
import OrganizationInformationItem from '@/components/widgets/simple-components/items/organizationInformationItem'
import { useRisksStore } from '@/store/risks'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  isEdit: Boolean
})

const route = useRoute()
const risksStore = useRisksStore()
const { item: risk } = storeToRefs(risksStore)

// const checklist = ref(props.data?.checklist)
// const isCreatedFromChecklist = ref(!isEmpty(checklist.value) || !!route?.query?.checklist)
// const categoryIsEmpty = ref(true)
const subdivisionList = ref([])
const departmentList = computed(() => {
  if (formData.department_id) {
    const list = subdivisionList.value.find((subdiv) => subdiv.id === formData.department_id)?.organizationSubdivisions.map((el) => ({
      ...el,
      label: el.name,
      value: el.id,
    }))
    
    return list
  }
  
  return []
})

const formRef = ref(null)
let formData = reactive({
  registration_date: null,
  sending_date_rkn: null,
  is_notice: false,
  place: null,
  description: '',
  consequence: '',
  measures: '',
  subdivision_id: '',
  department: '',
  group_id: '',
  object_id: 2, // "Нежелательное событие"
  med_type_id: null,
  action_category_id: null,
  registrator_id: null,
  responsible_id: null,
  reaction_index: 0,
  // reaction_index_text: '',
})

const responsibleUsersList = ref([])

const fetchUsers = async () => {
  const { data } = await api.get('responsible/get')
  if (data.data.length) {
    responsibleUsersList.value = data.data
  }
}

onMounted(() => {
  fetchUsers()

  if (props.isEdit) {
    // console.log('props.data edit', props.data);
    
    formData.registration_date = props.data.registration_date ? Number(moment(props.data.registration_date).format('x')) : null 
    formData.sending_date_rkn = props.data.sending_date_rkn ? Number(moment(props.data.sending_date_rkn).format('x')) : null 
    formData.is_notice = Boolean(Number(props.data.is_notice))
    formData.place = props.data.place 
    formData.description = props.data.description 
    formData.consequence = props.data.consequence 
    formData.measures = props.data.measures 
    formData.subdivision_id = props.data.subdivision?.id ? Number(props.data.subdivision?.id) : null
    formData.department = props.data.department 
    formData.department_id = props.data.department?.id ? Number(props.data.department?.id) : null
    formData.group_id = props.data.group_id ? Number(props.data.group_id) : ''
    // formData.object_id = props.data.object_id 
    formData.registrator_id = props.data.registrator_id ? Number(props.data.registrator_id) : ''
    formData.responsible_id = props.data.responsible_id ? Number(props.data.responsible_id) : ''
    formData.reaction_index = props.data.reaction_index 
    // formData.reaction_index_text = props.data.reaction_index_text 

    // из риска
    formData.action_category_id = props.data.risk.action_category_id ? Number(props.data.risk.action_category_id) : props.data.risk.action_category_id
    formData.med_type_id = props.data.risk.med_type_id ? Number(props.data.risk.med_type_id) : props.data.risk.med_type_id
    formData.process_id = props.data.risk.process_id ? Number(props.data.risk.process_id) : props.data.risk.process_id 
  }

  if (route?.query?.risk_id) {
    formData.risk_id = route?.query?.risk_id
    risksStore.fetchOne(route?.query?.risk_id)
  }

  fetchDepartments()
})

const rules = computed(() =>
  [
    'registration_date',
    'med_type_id',
    'process_id',
    'registrator_id',
    'responsible_id',
    // ##################
    'object_id',
    'assurance_id',
    'type_id'
  ].reduce(
    (acc, key) =>
      Object.assign(acc, {
        [key]: {
          required: true,
          message: REQUIRED_FIELD_MESSAGE,
          // trigger: ['input', 'blur'],
          type: 'number'
        }
      }),
    {
      med_type_id: {
        required: false,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      },
      process_id: {
        required: false,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      },
      action_category_id: {
        required: false,
        message: REQUIRED_FIELD_MESSAGE,
        trigger: ['input', 'blur'],
        type: 'number'
      },
      place: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
      },
      description: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
      },
      consequence: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
      },
      is_notice: {
        required: false,
        message: REQUIRED_FIELD_MESSAGE,
      },
      measures: {
        required: true,
        message: REQUIRED_FIELD_MESSAGE,
      },
    }
  )
)

const fetchDepartments = async () => {
  const response = await api.get('/organization/get-departments')
  if (response.status === 200) {
    subdivisionList.value = response.data.map((el) => {
      return {
        ...el,
        label: el.name,
        value: el.id,
      }
    })
  }
}

const actionCategoryRequest = computed(
  () =>
    `refbook/get?${serialize(
      {
        subject: 'action-category',
        process_id: formData.process_id,
        object_id: formData.object_id
      },
      true
    )}`
)

// При создании ИЗ РИСКА заполнить поля из риска
watch(risk, () => {
  if (!props.isEdit && risk.value.id) {
    formData.department_id = Number(risk.value.department?.id)
    formData.subdivision_id = Number(risk.value.subdivision?.id)
    formData.med_type_id = Number(risk.value.med_type_id)
    formData.process_id = Number(risk.value.process_id)
    formData.action_category_id = Number(risk.value.action_category_id)
  }
})

function actionCategoryFetched(options) {
  // categoryIsEmpty.value = isEmpty(options)
}

const responsibleUsersOptions = computed(() => {
  let array = []
  if (formData.subdivision_id && departmentList.value?.length) {
    const { members } = departmentList.value.find((subdiv) => subdiv.id === formData.subdivision_id)
    array = members.map((member) => ({
      value: Number(member.id),
      label: member.name,
    }))
  }
  
  if (!formData.subdivision_id && formData.department_id && subdivisionList.value?.length) {
    const { members } = subdivisionList.value.find((department) => department.id === formData.department_id)
    array = members.map((member) => ({
      value: Number(member.id),
      label: member.name,
    }))
  }
  
  if (!formData.department_id) {
    array = responsibleUsersList.value.map((member) => ({
      value: Number(member.id),
      label: member.name,
    }))
  }

  return array 
})

defineExpose({
  validate(cb) {
    formRef.value?.validate((errors) => {
      cb?.(errors, formData)
    })
  }
})
</script>

<template>
  <NForm ref="formRef" :model="formData" :rules="rules" label-placement="left" label-width="220px">
    <NFormItem style="padding-left: 220px;">
      <OrganizationInformationItem
        v-if="formData.risk_id"
        icon="white-number-icon"
        header="ID риска"
      >
        <router-link :to="'/risks/' + formData.risk_id">{{ formData.risk_id || "—" }}</router-link>
      </OrganizationInformationItem>
    </NFormItem>
    

    <NFormItem label="Дата регистрации" path="registration_date">
      <NDatePicker v-model:value="formData.registration_date" format="dd.MM.yyyy" class="mr-5" />
    </NFormItem>

    <div class="d-flex">
      <NFormItem label="Дата отправки Извещения" path="sending_date_rkn">
        <NDatePicker v-model:value="formData.sending_date_rkn" format="dd.MM.yyyy" class="mr-4" />
      </NFormItem>
      <NFormItem path="is_notice">
        <NCheckbox v-model:checked="formData.is_notice" size="large" label="Извещение в контролирующий орган" />
      </NFormItem>
    </div>

    <NFormItem label="Место нежелательного события" path="place">
      <NInput v-model:value="formData.place" type="textarea" />
    </NFormItem>

    <NFormItem label="Описание" path="description">
      <NInput v-model:value="formData.description" type="textarea" />
    </NFormItem>

    <NFormItem label="Последствия" path="consequence">
      <NInput v-model:value="formData.consequence" type="textarea" />
    </NFormItem>

    <NFormItem label="Принятые меры" path="measures">
      <NInput v-model:value="formData.measures" type="textarea" />
    </NFormItem>

    <NFormItem label="Подразделение" path="department_id">
      <NSelect v-model:value="formData.department_id" :options="subdivisionList" size="large" disabled />
    </NFormItem>

    <NFormItem label="Отдел" path="subdivision_id">
      <NSelect v-model:value="formData.subdivision_id" :options="departmentList" size="large" disabled />
    </NFormItem>

    <NFormItem label="Группа нежелательных событий" path="group_id">
      <EntrySelect
        v-model:value="formData.group_id"
        request="/inevent/get-groups"
        :clearable="true"
        label-field="name"
      />
    </NFormItem>

    <NFormItem label="Направление" path="med_type_id">
      <EntrySelect
        v-model:value="formData.med_type_id"
        disabled
        request="/organization/get-organization-medtypes"
      />
    </NFormItem>

    <NFormItem label="Раздел ВКК" path="process_id">
      <EntrySelect
        v-model:value="formData.process_id"
        disabled
        label-field="process.text"
        value-field="process_id"
        :request="formData.med_type_id ? `/organization/get-processes-by-med-id?id=${formData.med_type_id}` : null"
      />
    </NFormItem>

    <NFormItem label="Вид нежелательного события" path="action_category_id">
      <EntrySelect
        v-model:value="formData.action_category_id"
        disabled
        label-field="name"
        :request="actionCategoryRequest"
        @fetched="actionCategoryFetched"
      />
    </NFormItem>

    <NFormItem label="Кто зарегистрировал" path="registrator_id">
      <EntrySelect v-model:value="formData.registrator_id" :options="responsibleUsersOptions" label-field="name" sortable />
    </NFormItem>

    <NFormItem label="Ответственный" path="responsible_id">
      <EntrySelect v-model:value="formData.responsible_id" :options="responsibleUsersOptions" label-field="name" sortable />
    </NFormItem>

    <NFormItem label="Индекс реагирования" path="reaction_index">
      <NRadioGroup v-model:value="formData.reaction_index" size="large">
        <NRadioButton value="0">
          <span style="vertical-align: middle">Пациент не пострадал</span>
          <span
            style="width: 18px; height: 18px; vertical-align: middle"
            class="rounded-circle bg-dark d-inline-block ml-2"
          />
        </NRadioButton>
        <NRadioButton value="1">
          <span style="vertical-align: middle">Пациент пострадал</span>
          <span
            style="width: 18px; height: 18px; vertical-align: middle"
            class="rounded-circle bg-danger d-inline-block ml-2"
          />
        </NRadioButton>
      </NRadioGroup>

      <!-- <NInput v-model:value="formData.reaction_index_text" class="ml-2" size="large" /> -->
    </NFormItem>

    <!-- 
    <NFormItem label="Вид проверки" path="assurance_id">
      <EntrySelect v-model:value="formData.assurance_id" request="action/get-assurances" placeholder="Вид проверки" />
    </NFormItem>
    <NFormItem label="Тип мероприятия" path="type_id">
      <EntrySelect v-model:value="formData.type_id" request="action/get-types" placeholder="Тип мероприятия" />
    </NFormItem>
    
    <NFormItem label="Дата закрытия" path="plan_date">
      <NDatePicker v-model:value="formData.plan_date" format="dd.MM.yyyy" placeholder="Дата закрытия" />
    </NFormItem>
    
     -->
  </NForm>
</template>

<style scoped>
label {
  margin-bottom: auto;
}
</style>
