<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useDialog, NAlert } from 'naive-ui'
import { isEmpty } from 'lodash'
import MainTab from '@/components/pages/Actions/actionTabs/mainTab'
import Documents from '@/components/widgets/custom-components/tabs/organization-tabs/Documents'
import NextButton from '@/components/widgets/simple-components/buttons/nextButton'
import SpinLoader from '@/components/widgets/simple-components/spin/SpinLoader'
import NextAlert from '@/components/widgets/simple-components/alert/NextAlert'
import SelectProcessModal from '@/components/pages/Actions/actionComponents/selectProcessModal'
import { useEventsStore } from '@/store/events'
import { useStore } from 'vuex';

import useTarifExtension from '@/composables/useTarifExtension'

const store = useStore();
const currentUser = store.getters['auth/user']

const { hasPermission } = useTarifExtension()
const route = useRoute()
const dialog = useDialog()
const eventsStore = useEventsStore()
const { isLoading, isError, item: event } = storeToRefs(eventsStore)

const selectProcessModalRef = ref(null)

const currentTab = ref('main')

const userIsSuperAdmin = computed(() => JSON.parse(localStorage.user).role_id === 1)
const eventId = computed(() => route.params.id)
const allowEdit = computed(() => ![5, 6].includes(event.value?.action?.status_id))
const statusBtns = computed(() => event.value?.status?.buttons)
const topStatusBtns = computed(() =>
  !isEmpty(statusBtns.value)
    ? Object.keys(statusBtns.value)
        .filter((key) => key !== 'cancel_btn')
        .map((key) => statusBtns.value[key])
    : []
)
const showControls = computed(() => {
  // 1. В любом статусе Мероприятия, кроме Верификация - кнопки видят только Аудитор/Автор, Ответственный и Члены Комиссии ВКК и БМД.
  if (event.value?.action?.status?.id !== 4) {
    if (currentUser?.id === event.value?.action?.auditor?.id) {
      return true
    }
    if (currentUser?.id === event.value?.action?.responsible?.id) {
      return true
    }
    if (currentUser?.vkk === 1 || userIsSuperAdmin.value) {
      return true
    }
  }
  
  // 2. В статусе Мероприятия «Верификация» - кнопки видит только Аудитор/Автор и Члены Комиссии ВКК и БМД
  if (event.value?.action?.status?.id === 4) {
    if (currentUser?.id === event.value?.action?.auditor?.id) {
      return true
    }
    if (currentUser?.vkk === 1 || userIsSuperAdmin.value) {
      return true
    }
  }
  
  return false
})

onMounted(() => {
  eventsStore.fetchOne(eventId.value)
})

function changeTab(tab = 'main') {
  currentTab.value = tab
}

function deleteHandler() {
  const d = dialog.error({
    title: 'Подтвердите действие',
    content: 'Вы действительно хотите удалить этот объект?',
    positiveText: 'Удалить',
    negativeText: 'Отмена',
    onPositiveClick: () => {
      d.loading = true
      return eventsStore.deleteById(eventId.value)
    }
  })
}

function changeStatusHandler(btn) {
  if (
    !event.value.checklist &&
    (!event.value?.action?.process_id || !event.value?.action?.action_category_id) &&
    event.value.action.status_id === 4 &&
    btn.name !== 'Доработать' &&
    btn.name !== 'Отмена'
  ) {
    selectProcessModalRef.value?.openModal(event.value.action, ({ med_type_id, process_id, action_category_id }) =>
      eventsStore
        .update(
          {
            ...event.value.action,
            med_type_id,
            process_id,
            action_category_id
          },
          false
        )
        .then(() => {
          eventsStore.updateStatus(eventId.value, btn.status_id)
        })
    )
  } else {
    const d = dialog.warning({
      title: 'Подтвердите действие',
      content: 'Вы действительно хотите изменить статус мероприятия?',
      positiveText: 'Изменить статус',
      negativeText: 'Отмена',
      onPositiveClick: async () => {
        d.loading = true
        try {
          await eventsStore.updateStatus(eventId.value, btn.status_id)
        } catch (e) {
          console.log(e)
        }
      }
    })
  }
}
</script>

<template>
  <SpinLoader v-if="isLoading && !isError" class="py-5" size="2xl" />
  <template v-else-if="!isLoading && !isError && event">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__text">
          <div class="page-header__heading">Мероприятие #{{ eventId }}</div>
          <div class="page-header__second-heading">{{ event?.medtype?.text }}</div>
        </div>
      </div>
      <div class="page-header__right">
        <div v-if="showControls" class="page-header__controls">
          <template v-if="statusBtns">
            <NextButton
              v-for="(btn, key) in topStatusBtns"
              :key="key"
              :text="btn.name"
              type=""
              @click="changeStatusHandler(btn)"
            />
          </template>
          <NextButton
            v-if="allowEdit"
            :to="`/actions/${eventId}/edit`"
            type="edit"
            text="Редактировать"
            color="white"
          />
          <NextButton
            v-if="event?.action?.status_id === 1"
            type="close"
            text="Удалить"
            color="red"
            @click="deleteHandler"
          />
        </div>
      </div>
    </div>
    <div class="tabs">
      <div class="tab pointer" :class="{ active: currentTab === 'main' }" @click="changeTab('main')">
        Общая информация
      </div>
      <div class="tab pointer" :class="{ active: currentTab === 'documents' }" @click="changeTab('documents')">
        Документы
      </div>
    </div>
    <div class="tab-content">
      <div v-if="currentTab === 'main'" class="tab-item">
        <MainTab :data="event" />
      </div>

      <div v-else-if="currentTab === 'documents'">
        <NAlert v-if="!hasPermission(18)" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
          Для получения полного доступа необходимо сменить тариф
        </NAlert>
        <div :class="{ 'disabled-by-tarif': !hasPermission(18) }">
          <Documents type="action" :document-id="event?.action?.id" />
        </div>
      </div>
    </div>
    <NextButton
      v-if="statusBtns?.cancel_btn"
      class="mt-4"
      color="red"
      type=""
      :text="statusBtns?.cancel_btn?.name"
      @click="changeStatusHandler(statusBtns?.cancel_btn)"
    />
  </template>
  <NextAlert v-if="isError && !isLoading" type="error" :text="isError" />
  <SelectProcessModal ref="selectProcessModalRef" />
</template>

<style scoped lang="scss">
@media (max-width: 1000px) {
  .page-header {
    display: flex;
    flex-direction: column;
  }

  .page-header__left {
    margin-bottom: 20px;
  }

  .tab-content {
    overflow-x: scroll;
  }

  .tab-item {
    width: 900px;
  }
}

@media (max-width: 800px) {
  .page-header__text {
    flex-direction: column;
    align-items: flex-start
  }

  .page-header__second-heading {
    margin-left: 0;
  }
}
</style>