<script setup>
import { ref, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import { NButton, NModal, NForm, NFormItem, NInput, NAlert } from 'naive-ui'
import UsersTable from '@/components/pages/organization/UsersTable'
import useInvitation from '@/composables/useInvitation'

const store = useStore()
const { isLoading: sendingInvitation, sendInvitation } = useInvitation()

const props = defineProps({
  hasPermission: Boolean
})

const isOpen = ref(false)
const formRef = ref(null)
const formValue = reactive({ email: null })
const rules = {
  email: [
    {
      required: true,
      message: 'Поле обязательно для заполнения',
      trigger: 'input'
    },
    {
      validator: (rule, value) => !value || /\S+@\S+\.\S+/.test(value),
      message: 'Некорректный email'
    }
  ]
}

onMounted(() => {
  if (!store.getters.currentUser.id) {
    store.dispatch('USER_GET')
  }
})

function closeModal() {
  isOpen.value = false
  formValue.email = null
}

function submit(e) {
  e.preventDefault()
  formRef.value?.validate(async (errors) => {
    if (errors) return
    await sendInvitation(formValue.email)
    closeModal()
  })
}
</script>

<template>
  <NAlert v-if="!props.hasPermission" class="mb-5" :bordered="false" title="Доступен просмотр" type="info">
    Для получения полного доступа необходимо сменить тариф
  </NAlert>

  <div class="content-block" :class="{ 'disabled-by-tarif': !props.hasPermission }">
    <div v-if="store.getters.isSuperAdmin || store.getters.isQualityCommissioner" class="mb-4">
      <NButton type="primary" @click="isOpen = true">Отправить приглашение</NButton>
      <NModal
        v-model:show="isOpen"
        preset="card"
        title="Отправить приглашение по почте"
        :style="{ width: '480px' }"
        :bordered="false"
        @update:show="closeModal"
      >
        <NForm ref="formRef" :model="formValue" :rules="rules" required>
          <NFormItem label="Email" path="email">
            <NInput v-model:value="formValue.email" placeholder="Введите email" />
          </NFormItem>
        </NForm>
        <template #footer>
          <div class="d-flex justify-content-end">
            <NButton class="mr-2" secondary @click="closeModal">Отмена</NButton>
            <NButton type="primary" :loading="sendingInvitation" @click="submit">Отправить</NButton>
          </div>
        </template>
      </NModal>
    </div>
    <UsersTable />
  </div>
</template>
