import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})

instance.interceptors.request.use(
  async config => {
    config.headers = { 
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      'Accept': 'application/json',
    }
    return config;
  },
  error => {
    Promise.reject(error)
});

export default instance

// если на любой запрос приходит 401 — сделать редирект на страницу авторизации и удалить accessToken
