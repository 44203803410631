import { computed } from 'vue'
import { useOrganizationStore } from '@/store/organization'

const useTarifExtension = () => {
  const organizationStore = useOrganizationStore()
  const tarifExtensions = computed(() => organizationStore.organization?.tarif?.extensions)

  const hasPermission = (id) => Boolean(tarifExtensions.value?.find((ext) => ext.id === id)?.id)

  return {
    hasPermission
  }
}

export default useTarifExtension
