import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueTheMask from 'vue-the-mask'
import VueApexCharts from "vue3-apexcharts";

import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as helper from './helper'
import api from '@/api'
import './styles/main.scss'

const pinia = createPinia()
const app = createApp(App)

app.config.globalProperties.$helper = helper
app.config.globalProperties.$api = api

app.use(VueTheMask)
app.use(store)
app.use(pinia)
app.use(router)
app.use(VueApexCharts)

app.mount('#app')
